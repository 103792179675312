import axiosInstance from "../../redux/apis/api.service";
import { environment } from "../../environments/environment";
const useAdminHelper = () => {
	const uploadFile = async (body, purpose, id) => {
		const endpoint = `${environment.loginUrl}/file/upload?purpose=${purpose}&id=${id}`;
		return await axiosInstance().post(endpoint, body);
	};

	const getGroups = async () => {
		const endpoint = `${environment.loginUrl}/groups`;
		return await axiosInstance().get(endpoint);
	};

	return {
		uploadFile,
		getGroups,
	};
};

export default useAdminHelper;
