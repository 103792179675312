import axiosInstance from "../../redux/apis/api.service";
import { environment } from "../../environments/environment";
const usePackages = () => {
	const getPackages = async (body) => {
		const endpoint = environment.packagesURL + `?${jsonToQueryString(body)}`;
		return await axiosInstance().get(endpoint);
	};

	const getPackageById = async (packageId) => {
		let endpoint = `${environment.packagesURL}/${packageId}`;
		return await axiosInstance().get(endpoint);
	};

	const deletePackage = async (packageId) => {
		let endpoint = `${environment.packagesURL}/${packageId}`;
		return await axiosInstance().delete(endpoint);
	};

	const editPackage = async (packageId, body) => {
		let endpoint = `${environment.packagesURL}/${packageId}`;
		return await axiosInstance().put(endpoint, body);
	};

	const createPackage = async (body) => {
		let endpoint = `${environment.packagesURL}`;
		return await axiosInstance().post(endpoint, body);
	};

	return {
		getPackageById,
		getPackages,
		createPackage,
		editPackage,
		deletePackage,
	};
};

function jsonToQueryString(json) {
	return Object.keys(json)
		.filter(function (key) {
			return json[key] !== null && json[key] !== undefined;
		})
		.map(function (key) {
			return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
		})
		.join("&");
}

export default usePackages;
