import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const profileApi = createApi({
	reducerPath: "profileApi",
	tagTypes: ["Profile"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/profiles`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getProfile: builder.query({
			query: (id) => `/${id}`,
			providesTags: ["Profile"],
			transformResponse: function (response, meta, arg) {
				return response?.data;
			},
		}),
		createProfile: builder.mutation({
			query: (data) => ({
				url: `/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Profile"],
		}),
		updateProfile: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `/${id}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["Profile"],
		}),
	}),
});

// export const { trigger, lastArg } =
//   profileApi.endpoints.getProfile.useLazyQuerySubscription();

export const {
	useGetProfileQuery,
	useCreateProfileMutation,
	useUpdateProfileMutation,
	useLazyGetProfileQuery,
} = profileApi;
