import React from "react";
import {makeStyles} from "@material-ui/core";
import {
    Box,
    TextField,
  } from "@material-ui/core";
  import { Autocomplete } from "@mui/material";

  const useStyle = makeStyles((theme) => ({
    flexContainer: {
      display: "flex",
      flexDirection: "column",
    },
  }));

function SelectTagsLayout (props) {
  const styles = useStyle();
    return (
        <Box className={styles.flexContainer}>
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.res}
            onChange={(e,val)=>{
              if(val){
                props.setTags([...props.tags,val])
              }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Search by tags" />}
        />
        </Box>
      );
}

export default SelectTagsLayout;