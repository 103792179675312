import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CustomizeToastSuccess = (message, callback, timer = 2000) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: timer,
    onClose: callback ? callback : null,
  });
};
export const CustomizeToastError = (message, callback, timer = 2000) => {
  return toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: timer,
    onClose: callback ? callback : null,
  });
};
