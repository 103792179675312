import axios from "axios";
import { loginFailure, loginStart, loginSuccess } from "./authSlice";
import { CustomizeToastError } from "../../components/Toasts/Toasts";
import { environment } from "../../environments/environment";

const Login = async (user, dispatch) => {
	dispatch(loginStart());
	try {
		// const res = await axios.post("auth/login", user);
		const res = await axios.post(`${environment.loginUrl}/auth/login`, user, {
			headers: { "Access-Control-Allow-Origin": "*" },
		});
		console.log(res);
		if (res.data?.success) {
			console.log(res.data.data);
			dispatch(loginSuccess(res.data.data));
		}
		if (res?.data?.success === false) {
			CustomizeToastError(res?.data?.message);
			dispatch(loginFailure());
		}
	} catch (err) {
		dispatch(loginFailure());
	}
};

export default Login;
