import React from "react";
import { Header } from "../../components/Header";
import { Box } from "@mui/material";

function Layout_layout (props) {
    return (
		<div>
			<Header />
			<Box>{props.children}</Box>
		</div>
	);
}

export default Layout_layout;