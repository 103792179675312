import { uniq } from "lodash";
import React, { useEffect, useState } from "react";
import TagLayout from "../../designs/NewQuestion/TagLayout";

const Tag = ({ menu, placeholder, multiple, onChange, defaultValues, onClick }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selected, setSelected] = useState([]);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		setSelected(defaultValues ?? []);
		onChange({
			target: {
				value: defaultValues,
			},
		});
	}, [defaultValues]);

	

	const handleSelect = (value, isCancel = false) => {
		let finalValue = [];
	  
		if (isCancel) {
			if (typeof selected === "object" && selected.includes(value)) {
				setSelected(selected.filter((val) => val !== value));
				finalValue = selected.filter((val) => val !== value);
			  } else if (typeof selected === "string" && selected === value) {
				setSelected("");
				finalValue = "";
			  }
		}
		else {
		  if (typeof selected === "object" && selected.includes(value)) {
			setSelected(selected.filter((val) => val !== value));
			finalValue = selected.filter((val) => val !== value);
		  } else if (typeof selected === "string" && selected === value) {
			setSelected("");
			finalValue = "";
		  } else {
			if (multiple) {
			  setSelected([...selected, value]);
			  finalValue = [...selected, value];
			} else {
			  setSelected([value]);
			  finalValue = [value];
			}
		  }
		}
	  
		onChange({
		  target: {
			value: uniq(finalValue),
		  },
		});
	  
		handleClose();
	  };
	  

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<TagLayout
		   placeholder={placeholder}
		   handleClick={handleClick}
		   id={id}
		   open={open}
		   anchorEl={anchorEl}
		   handleClose={handleClose}
		   menu={menu}
		   handleSelect={handleSelect}
		   selected={selected}
		/>
	)
};

export default Tag;
