import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";

export const AddNote = ({ clic, setClic }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setClic(false);
  };
  return (
    <Dialog open={clic} onClose={handleClose}>
      <DialogContent
        style={{
          width: "300px",
          height: "500px",
          backgroundColor: "#E5F0FF",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Stack
          direction="column"
          style={{
            marginTop: 25,
            display: "flex",

            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 290,
              maxWidth: "100%",
            }}
          >
            <TextField
              id="fullWidth"
              fullWidth
              maxRows={3}
              multiline
              label="New Title"
              variant="outlined"
              placeholder="Write something here..."
            />
          </Box>
          <Box
            sx={{
              width: 290,
              maxWidth: "100%",
              marginTop: 25,
            }}
          >
            <TextField
              id="fullWidth"
              fullWidth
              multiline
              rows={10}
              label="New Notification"
              variant="outlined"
              placeholder="Write something here..."
            />
          </Box>
          <Button
            style={{
              width: "112px",
              height: "47px",
              backgroundColor: "#3D76CA",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
              borderRadius: "9px",
              color: "white",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              marginTop: 25,
            }}
          >
            Add New{" "}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
