import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const regionApi = createApi({
	reducerPath: "regionApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/region`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),

	tagTypes: ["Districts"],
	endpoints: (builder) => ({
		getAllDistricts: builder.query({
			query: () => `/districts`,
			providesTags: ["Districts"],
		}),
		createDistrict: builder.mutation({
			query: (data) => ({
				url: `/district`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Districts"],
		}),
	}),
});

export const { useGetAllDistrictsQuery, useCreateDistrictMutation } = regionApi;
