import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import PackagesLayout from "../../designs/PackagesBlockList/PackagesLayout";
import usePackages from "../../hooks/generic/usePackages";
import { useParams, useHistory } from "react-router-dom";
import {
	CustomizeToastError,
	CustomizeToastSuccess,
} from "../../components/Toasts/Toasts";

export const Packages = () => {
	const history = useHistory();

	const [open, setOpen] = useState();
	const [subjects, setSubjects] = useState([]);
	const [classNames, setClassNames] = useState([]);
	const [series, setSeries] = useState([]);
	const [totalDurationMin, setTotalDurationMin] = useState();
	const [selectedItems, setSelectedItems] = useState([]);
	const [domain, setDomain] = useState([]);
	const [ques, setQues] = useState([]);

	var params = useParams();
	params = params.packageId;
	const { editPackage, getPackageById } = usePackages();

	let calculatedGrades = useMemo(() => {
		let gradesMap = {
			"Class-01": "Class-01",
			"Class-02": "Class-02",
			"Class-03": "Class-03",
			"Class-04": "Class-04",
			"Class-05": "Class-05",
			"Class-06": "Class-06",
			"Class-07": "Class-07",
			"Class-08": "Class-08",
			"Class-09": "Class-09",
			"Class-10": "Class-10",
		};
		let newGrades = classNames.map((grade) => gradesMap[grade]);
		return newGrades;
	}, [classNames]);
	useEffect(() => {
		const getPackageDetails = async () => {
			const resp = await getPackageById(params);
			//console.log(resp);
			//setPackageName(resp.data.packageName);
			if (Array.isArray(resp?.data?.grade)) setClassNames(resp?.data?.grade)
			else setClassNames([resp?.data?.grade])
			setSubjects(resp.data.subject);
			setTotalDurationMin(resp.data.totalDurationMin);
			setSeries(resp.data.series);
			//setTotalMarks(resp.data.totalMarks);
			let temp = [];
			resp.data.questions.forEach((item) => {
				temp.push(item._id);
			})
			console.log(temp);
			setQues(temp);
		};

		if (params !== undefined) getPackageDetails();
	}, [params]);

	useEffect(() => {
		console.log(selectedItems);
	}, [selectedItems]);

	useEffect(() => {
		console.log(ques);
	}, [ques]);

	console.log(ques.map((str) => ({ id: str })));

	const handleSubmit = async () => {
		let questions = ques;
		if (questions === undefined || questions.length === 0) questions = selectedItems;
		else questions = [...questions, ...selectedItems];
		let unique = [];
		questions.forEach((element) => {
			if (!unique.includes(element)) {
				unique.push(element);
			}
		});
		console.log(ques, unique);
		var editResp = await editPackage(params, {
			questions: unique.map((str) => ({ id: str })),
		});
		if (editResp.data) {
			CustomizeToastSuccess("Package edited successfully");
			history.push("/");
		} else {
			CustomizeToastError("Something Went Wrong !");
		}
	};

	return (
		<PackagesLayout
			setOpen={setOpen}
			open={open}
			subjects={subjects}
			classNames={classNames}
			series={series}
			totalDurationMin={totalDurationMin}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			handleSubmit={handleSubmit}
			domain={domain}
			setDomain={setDomain}
			calculatedGrades={calculatedGrades}
			ques={ques}
		/>
	);
};
