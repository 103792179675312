import React from "react";
import { useState, useEffect } from "react";
import DomainBoxLayout from "../../designs/PackagesBlockList/DomainBoxLayout";
import { useQuery } from "react-query";
import useItemsHelper from "../../hooks/generic/useItems";



const DomainBox = (props) => {

  const { getCategories } = useItemsHelper();
  const { data: categories } = useQuery(["categories"], getCategories);
  const [data, setData] = useState();



useEffect(() => {
    if (categories && categories.data) {

        if(props.subjects[0]==="Science"){
      let newData = [];
      for (let key in categories.data.Science) {
        for (let it in categories.data.Science[key]) {
          newData.push(it);
        }
      }
      setData(newData);
    }

      else if(props.subjects[0]==="Math"){
      let newData = [];
      for (let key in categories.data.Math) {
        for (let it in categories.data.Math[key]) {
          newData.push(it);
        }
      }
      setData(newData);
    }

    else if(props.subjects[0]==="English"){
        let newData = [];
        for (let key in categories.data.English) {
          for (let it in categories.data.English[key]) {
            newData.push(it);
          }
        }
        setData(newData);
      }

      else if(props.subjects[0]==="Technology"){
        let newData = [];
        for (let key in categories.data.Technology) {
          for (let it in categories.data.Technology[key]) {
            newData.push(it);
          }
        }
        setData(newData);
      }
    }
  }, [categories]);

  return (
    <DomainBoxLayout
       domain={props.domain}
       setDomain={props.setDomain}
       data={data}
    />
  );
};

export default DomainBox;
