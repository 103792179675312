import React from "react";
import QuestionsTable from "../../components/QuestionsTable/QuestionsTable";
import {
    alpha,
    Box,
    Button,
    FormControl,
    InputBase,
    styled,
    Typography,
  } from "@material-ui/core";

function AddQuestionsToPackageLayout(props) {
    return (
    <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        noValidate
        autoComplete="off"
        padding={5}
      >
        <div>
            <Typography variant="subtitle2" gutterBottom component="div">
            Assesment Name: {props.assessmentName}
            </Typography>
        </div>
        <Button onClick={props.handleSubmit} variant="contained">
        Submit
        </Button>
        <>
        <Typography variant="subtitle2" gutterBottom component="div">
            Select Questions (selected so far: {props.selectedQuestions.length})
        </Typography>
        <QuestionsTable
            onRowsSelect={(rows) => {
            props.setSelectedQuestions(rows);
            }}
        />
        </>
      </Box>
      );
}

export default AddQuestionsToPackageLayout;