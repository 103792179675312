import React, { useEffect } from "react";
import { useState, useRef, useMemo } from "react";
import DialogBoxLayout from "../../designs/NewQuestion/DialogBoxLayout";



export const DialogBox = ({
	placeholder,
	click,
	setClick,
	data,
	setData,
	path,
}) => {
	const editor = useRef(null);
	const [content, setContent] = useState("");
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setContent(data);
	}, [data]);

	const config = useMemo(() => {
		return {
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: placeholder || "Start typings...",
		};
	}, [placeholder]);
	const handleClose = () => {
		setData(content);
		setClick(false);
	};

	return (
		<DialogBoxLayout
		    click={click}
			handleClose={handleClose}
			path={path}
			editor={editor}
			content={content}
			config={config}
			setContent={setContent}
		/>
	)
};
