import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import { AddNote } from "./AddNote";

export const NotificationDialog = ({ click, setClick }) => {
  const [open, setOpen] = useState(false);
  const [ope, setOpe] = useState();

  const handleClose = () => {
    setClick(false);
  };
  return (
    <Dialog open={click} onClose={handleClose}>
      <DialogContent
        style={{
          width: "300px",
          height: "250px",
          backgroundColor: "#E5F0FF",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          direction="column"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Add Comment</Typography>
          <Box
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "30px",
              backgroundColor: "#3D76CA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <AddIcon onClick={() => setOpe(true)} color="#FFFFFF" />
          </Box>
        </Stack>
      </DialogContent>
      <AddNote clic={ope} setClic={setOpe} />
    </Dialog>
  );
};
