import React from "react";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Typography,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useTheme } from "@material-ui/core";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { tagValues } from "../NewPackage/NewPackageLayout";

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(1),
	},
	"& .MuiInputBase-input": {
		borderRadius: 14,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		fontSize: 16,
		padding: "15px 24px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: ["Urbanist"].join(","),
	},
}));

function PackageDialogLayout(props) {
	const theme = useTheme();
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={props.click}
			onClose={props.handleClose}
		>
			<DialogContent
				style={{
					height: "320px",
					backgroundColor: "#F8FAFD",
					boxShadow: "0px 6px 21px rgba(0, 0, 0, 0.85)",
					borderRadius: "10px",
				}}
			>
				<Box style={{ display: "flex" }}>
					<FormControl fullWidth sx={{ color: "#616060", marginTop: 3 }}>
						<InputLabel
							id="demo-simple-select-standard-label"
							sx={{
								color: "#191818",
								fontFamily: "Inter",
								fontWeight: 700,
								fontSize: "20px",
							}}
						>
							Grade
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={props.classNames}
							onChange={(e) => props.setClassNames(e.target.value)}
							label="Grade"
							inputProps={{
								style: {
									color: "#191818",
									fontFamily: "Inter",
									fontWeight: 700,
									fontSize: "20px",
								},
							}}
							input={<BootstrapInput />}
						>
							{props.groups?.data?.data.map((classObj) => (
								<MenuItem key={classObj.id} value={classObj.groupName}>
									{classObj.groupName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl
						fullWidth
						sx={{ color: "#616060", marginTop: 3, marginLeft: 1 }}
					>
						<InputLabel
							id="demo-simple-select-standard-label"
							sx={{
								color: "#191818",
								fontFamily: "Inter",
								fontWeight: 700,
								fontSize: "20px",
							}}
						>
							Test Delivery Type
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							label="Test Delivery Type"
							value={props.series}
							onChange={props.handleChange}
							inputProps={{
								sx: {
									color: "#191818",
									fontFamily: "Inter",
									fontWeight: 700,
									fontSize: "20px",
								},
							}}
							input={<BootstrapInput />}
						>
							{tagValues.map((tag) => (
								<MenuItem key={tag} value={tag}>
									{tag}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box style={{ display: "flex" }}>
					<FormControl fullWidth sx={{ color: "#616060", marginTop: 3 }}>
						<InputLabel
							id="demo-simple-select-standard-label"
							sx={{
								color: "#191818",
								fontFamily: "Inter",
								fontWeight: 700,
								fontSize: "20px",
							}}
						>
							Subject
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							label="Subject"
							value={props.subjects}
							onChange={(e) => props.setSubjects(e.target.value)}
							inputProps={{
								sx: {
									color: "#191818",
									fontFamily: "Inter",
									fontWeight: 700,
									fontSize: "20px",
								},
							}}
							input={<BootstrapInput />}
						>
							{props.categories &&
								Object.keys(props.categories?.data).map((key) => (
									<MenuItem value={key}>{key}</MenuItem>
								))}
						</Select>
					</FormControl>

					<FormControl fullWidth sx={{ color: "#616060", marginTop: 3 }}>
						<InputLabel
							id="demo-simple-select-standard-label"
							sx={{
								color: "#191818",
								fontFamily: "Inter",
								fontWeight: 700,
								fontSize: "20px",
							}}
						>
							Domain
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							label="Domain"
							value={props.domain}
							onChange={(e) => props.setDomain(e.target.value)}
							inputProps={{
								sx: {
									color: "#191818",
									fontFamily: "Inter",
									fontWeight: 700,
									fontSize: "20px",
								},
							}}
							input={<BootstrapInput />}
						>
							{props.subjects === "Science" &&
								props.science?.map((i) => <MenuItem value={i}>{i}</MenuItem>)}
							{props.subjects === "Math" &&
								props.math?.map((i) => <MenuItem value={i}>{i}</MenuItem>)}
							{props.subjects === "English" &&
								props.english?.map((i) => <MenuItem value={i}>{i}</MenuItem>)}
							{props.subjects === "Technology" &&
								props.tech?.map((i) => <MenuItem value={i}>{i}</MenuItem>)}
						</Select>
					</FormControl>

					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						style={{ marginTop: 30, padding: 2 }}
					>
						<Box
							display="flex"
							style={{
								width: "147px",
								height: "54px",
								backgroundColor: "#F8FAFD",
								border: "1px solid #616060",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
								borderRadius: "9px",
								marginLeft: 7,
							}}
						>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon style={{ color: "white" }} />
							</Box>
							<Box
								style={{
									width: "65px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Stack direction="column">
									<Typography
										style={{
											fontFamily: "Inter",
											fontWeight: 400,
											fontSize: "12px",
											/* identical to box height */
											color: "#000000",
											marginTop: -2,
										}}
									>
										Hours
									</Typography>
									<Stack
										direction="row"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontFamily: "Inter",
												fontWeight: 700,
												fontSize: "20px",
												color: "#C6C0C0",
											}}
										>
											00
										</Typography>
										<KeyboardArrowDownIcon style={{ color: "#C9CACC" }} />
									</Stack>
								</Stack>
							</Box>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									transform: "rotate(180deg)",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon style={{ color: "white" }} />
							</Box>
						</Box>
						<Box
							display="flex"
							style={{
								width: "147px",
								height: "54px",
								backgroundColor: "#F8FAFD",
								border: "1px solid #616060",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
								borderRadius: "9px",
								marginLeft: 2,
							}}
						>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon
									style={{ color: "white" }}
									onClick={() =>
										props.setTotalDurationMin(props.totalDurationMin - 1)
									}
								/>
							</Box>
							<Box
								style={{
									width: "65px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Stack direction="column">
									<Typography
										style={{
											fontFamily: "Inter",
											fontWeight: 400,
											fontSize: "12px",
											/* identical to box height */
											color: "#000000",
											marginTop: -2,
										}}
									>
										Minute
									</Typography>
									<Stack
										direction="row"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										onClick={() =>
											props.setTotalDurationMin(props.totalDurationMin + 1)
										}
									>
										<Typography
											style={{
												fontFamily: "Inter",
												fontWeight: 700,
												fontSize: "20px",
												color: "#C6C0C0",
											}}
										>
											{props.totalDurationMin}
										</Typography>
										{/* <CustomInput
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: "20px",
                            color: "#C6C0C0",
                          }}
                          value={props.totalDurationMin}
                          onChange={(e) => {
                            props.setTotalDurationMin(e.target.value);
                          }}
                        /> */}
										<KeyboardArrowDownIcon style={{ color: "#C9CACC" }} />
									</Stack>
								</Stack>
							</Box>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									transform: "rotate(180deg)",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon style={{ color: "white" }} />
							</Box>
						</Box>
						<Box
							display="flex"
							style={{
								width: "147px",
								height: "54px",
								backgroundColor: "#F8FAFD",
								border: "1px solid #616060",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
								borderRadius: "9px",
								marginLeft: 2,
							}}
						>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon style={{ color: "white" }} />
							</Box>
							<Box
								style={{
									width: "65px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Stack direction="column">
									<Typography
										style={{
											fontFamily: "Inter",
											fontWeight: 400,
											fontSize: "12px",
											/* identical to box height */
											color: "#000000",
											marginTop: -2,
										}}
									>
										Second
									</Typography>
									<Stack
										direction="row"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontFamily: "Inter",
												fontWeight: 700,
												fontSize: "20px",
												color: "#C6C0C0",
											}}
										>
											00
										</Typography>
										<KeyboardArrowDownIcon style={{ color: "#C9CACC" }} />
									</Stack>
								</Stack>
							</Box>
							<Box
								style={{
									width: "43px",

									backgroundColor: "#C9CACC",
									borderRadius: " 8px 0px 0px 8px",
									borderTop: "1px",
									transform: "rotate(180deg)",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChevronLeftIcon style={{ color: "white" }} />
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 30,
					}}
				>
					<Button
						style={{
							width: "180px",
							height: "72px",
							backgroundColor: "#3D76CA",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "11px",
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 700,
							fontSize: "18px",
							color: "#FFFFFF",
						}}
						onClick={props.handleSubmit}
					>
						Save/Next
					</Button>
					<Button
						style={{
							marginLeft: 8,
							width: "180px",
							height: "72px",
							backgroundColor: "#EB5160",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "11px",
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 700,
							fontSize: "18px",
							color: "#FFFFFF",
						}}
						onClick={props.handleClose}
					>
						Cancel
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default PackageDialogLayout;
