import React, { useState } from "react";
import QuestionsTable from "../../components/QuestionsTable/QuestionsTable";
import Layout from "../../pages/layout/Layout";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	InputAdornment,
	TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DropdownBox from "../../pages/NewQuestion/DropdownBox";
import { Box, Grid } from "@material-ui/core";

function DashboardLayout(props) {
	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Layout>
			<Grid container style={{ backgroundColor: "#EFEFEF", width: "auto" }}>
				<Grid item xl={3} lg={3} md={4} sm={5} xs={12}>
					<DropdownBox
						style={{
							backgroundColor: "#3D76CA",
							width: "92%",
							borderBottomRightRadius: "16px",
						}}
						setTags={props.setTags}
						tags={props.tags}
						reset={true}
						selectedTags={props.selectedTags}
						setSelectedTags={props.setSelectedTags}
						op={"Filter"}
						startIcon={<SearchIcon />}
					/>
				</Grid>
				<Grid item xl={9} lg={9} md={8} sm={7} xs={12}>
					<Grid container spacing={2} style={{ marginTop: 10 }}>
						<Grid
							item
							lg={8}
							md={6}
							display="flex"
							justifyContent="flex-start"
							alignItems="flex-start"
						>
							<Box
								style={{
									width: { lg: "580px", md: "300px" },
									height: "58px",
								}}
							>
								<TextField
									InputProps={{
										endAdornment: (
											<InputAdornment position="start">
												<IconButton>
													<SearchIcon style={{ color: "#CAC2C2" }} />
												</IconButton>
											</InputAdornment>
										),
										sx: {
											borderRadius: "12px",
											backgroundColor: "#FFFFFF",
											width: {
												lg: "580px",
												md: "300px",
											},
											height: "58px",
											padding: 2,
										},
									}}
									variant="outlined"
									type="text"
									placeholder="Search"
								/>
							</Box>
						</Grid>
						{props.isAuthor && props.subject && (
							<Box
								display={"flex"}
								alignItems={"center"}
								sx={{
									flexDirection: "row",
									justifyContent: "end",
								}}
								gridGap={"10px"}
								pr={1}
							>
								<Grid
									item
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "flex-end",
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#3c76ca",
											height: "58px",
											borderRadius: "10px",
										}}
										onClick={() => props.history.push("/question/create")}
									>
										Add New Question
									</Button>
								</Grid>
								{props?.selectedItems?.length > 0 && (
									<>
										<Grid
											item
											style={{
												display: "flex",
												justifyContent: "flex-end",
												alignItems: "flex-end",
											}}
										>
											<Button
												variant="contained"
												sx={{
													height: "58px",
													backgroundColor: "#3c76ca",
													borderRadius: "10px",
												}}
												onClick={handleClickOpen}
											>
												Create a Group
											</Button>
										</Grid>
									</>
								)}
							</Box>
						)}
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							display="flex"
							justifyContent="flex-end"
							alignItems="flex-end"
						>
							<QuestionsTable
								tags={props.tags}
								selectedItems={props.selectedItems}
								setSelectedItems={props.setSelectedItems}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Group</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please input a group name to create a group from the selected
						questions
					</DialogContentText>
					<TextField
						sx={{ my: 2 }}
						autoFocus
						value={props.group}
						onChange={(e) => props.setGroup(e.target.value)}
						margin="dense"
						id="name"
						label="Group name"
						type="text"
						fullWidth
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						variant="contained"
						onClick={() => {
							handleClose();
							props.groupItems();
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Layout>
	);
}

export default DashboardLayout;
