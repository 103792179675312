import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Search } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import Tag from "../../pages/NewQuestion/Tag";



function DomainBoxLayout(props) {
  return (
    <Box
      style={{
        backgroundColor: "#3D76CA",
        width: "100%",
        height: "1000px",
        borderBottomRightRadius: "16px",
      }}
    >
      <Box style={{ marginLeft: 30 }}>
        <Box sx={{ pt: 2 }}>
          <Typography style={{ color: "#77ADFC" }}>Select Filter</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Button
          sx={{ color: "#FFFFFF" }}
          startIcon={<RefreshIcon />}
          onClick={props.handleReset}
          >
            Reset
          </Button>
          <Button
            sx={{ color: "#FEFEFF" }}
            startIcon={<Search />}
            onClick={props.handleFilter}
          >
            Filter
          </Button> */}
        </Box>
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <div>
          <Box sx={{ display: "flex", flexDirection: "column" }}>

            <Box
              width="95%"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <Tag
                placeholder={"Domain"}
                menu={props.data?.map((group) => group)}
                multiple={false}
                defaultValues={props.domain}
                onChange={(e) => props.setDomain(e.target.value)}
              />
            </Box>
            <Divider
              style={{
                backgroundColor: "#C0D3EF",
                width: "80%",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />

            
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default DomainBoxLayout;
