import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const sentimentsApi = createApi({
	reducerPath: "sentimentsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/sentiments`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),

	tagTypes: ["Sentiments"],
	endpoints: (builder) => ({
		getAllSentiments: builder.query({
			query: () => `/`,
			providesTags: ["Sentiments"],
		}),
	}),
});

export const { useGetAllSentimentsQuery } = sentimentsApi;
