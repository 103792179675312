import React from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import Tag from "../../pages/NewQuestion/Tag";
import { Search } from "@mui/icons-material";
import { flattenDeep, uniq } from "lodash";
import RefreshIcon from "@mui/icons-material/Refresh";

function DropdownBoxLayout(props) {
  const categoriesData = props.categories?.data ?? {};

  return (
    <Box sx={props.style}>
      <Box style={{ marginLeft: 30 }}>
        <Box sx={{ pt: 2 }}>
          <Typography style={{ color: "#77ADFC" }}>Select Tags</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.reset && (
            <div>
              <Button
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  props.setTags([]);
                //   props.setSelectedTags({
                //     grades: [],
                //     strand: [],
                //     domain: [],
                //     topic: [],
                //     concept: [],
                //   });
                }}
                startIcon={<RefreshIcon />}
              >
                Reset
              </Button>
              <Button
                onClick={() => {
                    props.setTags(uniq(flattenDeep(Object.values(props.selectedTags))));
                    //props.setTags(uniq(Object.values(props.selectedTags)));
                }}
                sx={{ color: "#FEFEFF" }}
                startIcon={<Search />}
              >
                Filter
              </Button>
            </div>
          )}
        </Box>
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <Tag
          placeholder={"Grades"}
          menu={props.groups?.data?.data
            ?.map((group) => group?.groupName)
            .sort((a, b) => a.split("-")[1] - b.split("-")[1])}
          multiple={false}
          defaultValues={props.selectedTags.grades}
          onChange={(e) => props.onChange(e, "grades")}
        />
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <Tag
          placeholder={"Strand"}
          menu={Object.keys(categoriesData)}
          multiple={false}
          onChange={(e) => props.onChange(e, "strand")}
          defaultValues={props.selectedTags.strand}
        />
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <Tag
          placeholder={"Domain"}
          menu={
            props.selectedTags["strand"] &&
            props.selectedTags["strand"][0] &&
            categoriesData[props.selectedTags["strand"][0]]
              ? Object.keys(categoriesData[props.selectedTags["strand"][0]])
              : []
          }
          multiple={false}
          onChange={(e) => props.onChange(e, "domain")}
          defaultValues={props.selectedTags.domain}
        />
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <Tag
          placeholder={"Topic"}
          menu={
            props.selectedTags["strand"] &&
            props.selectedTags["domain"] &&
            props.selectedTags["strand"][0] &&
            props.selectedTags["domain"][0] &&
            categoriesData[props.selectedTags["strand"][0]] &&
            categoriesData[props.selectedTags["strand"][0]][
              props.selectedTags["domain"][0]
            ]
              ? Object.keys(
                  categoriesData[props.selectedTags["strand"][0]][
                    props.selectedTags["domain"][0]
                  ]
                )
              : []
          }
          multiple={false}
          defaultValues={props.selectedTags.topic}
          onChange={(e) => props.onChange(e, "topic")}
        />
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        <Tag
          placeholder={"Concept"}
          menu={
            props.selectedTags["strand"] &&
            props.selectedTags["domain"] &&
            props.selectedTags["topic"] &&
            props.selectedTags["topic"][0] &&
            props.selectedTags["strand"][0] &&
            props.selectedTags["domain"][0] &&
            categoriesData[props.selectedTags["strand"][0]] &&
            categoriesData[props.selectedTags["strand"][0]][
              props.selectedTags["domain"][0]
            ] &&
            categoriesData[props.selectedTags["strand"][0]][
              props.selectedTags["domain"][0]
            ][props.selectedTags["topic"][0]]
              ? Object.keys(
                  categoriesData[props.selectedTags["strand"][0]][
                    props.selectedTags["domain"][0]
                  ][props.selectedTags["topic"][0]]
                )
              : []
          }
          multiple={false}
          onChange={(e) => props.onChange(e, "concept")}
          defaultValues={props.selectedTags.concept}
        />
        <Divider
          style={{
            backgroundColor: "#C0D3EF",
            width: "80%",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        />
        {!props.reset && (
          <>
            <Tag
              placeholder={"Foundational Concepts"}
              menu={
                props.selectedTags["strand"] &&
                props.selectedTags["domain"] &&
                props.selectedTags["topic"] &&
                props.selectedTags["strand"].length > 0 &&
                props.selectedTags["domain"].length > 0 &&
                props.selectedTags["topic"].length > 0 &&
                categoriesData[props.selectedTags["strand"][0]] &&
                categoriesData[props.selectedTags["strand"][0]][
                  props.selectedTags["domain"][0]
                ] &&
                categoriesData[props.selectedTags["strand"][0]][
                  props.selectedTags["domain"][0]
                ][props.selectedTags["topic"][0]]
                  ? Object.keys(
                      categoriesData[props.selectedTags["strand"][0]][
                        props.selectedTags["domain"][0]
                      ][props.selectedTags["topic"][0]]
                    )
                  : []
              }
              multiple={false}
              onChange={(e) => props.setFoundationTags(e.target.value)}
              defaultValues={props.foundationTags}
            />
            <Divider
              style={{
                backgroundColor: "#C0D3EF",
                width: "80%",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Tag
              placeholder={"Cognitive Skill"}
              menu={["Analyzing", "Understanding", "Applying"]}
              multiple={false}
              onChange={(e) => props.setSkill(e.target.value)}
              defaultValues={props.skill}
            />
            <Divider
              style={{
                backgroundColor: "#C0D3EF",
                width: "80%",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default DropdownBoxLayout;
