import React from "react";
import {
	Avatar,
	Box,
	Button,
	Card,
	Checkbox,
	Grid,
	IconButton,
	List,
	Typography,
} from "@mui/material";
import { Close, DeleteForever } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import DropdownBox from "../../pages/NewQuestion/DropdownBox";
import EditComponent from "../../pages/NewQuestion/EditComponent";
import Layout from "../../pages/layout/Layout";
import AddIcon from "@mui/icons-material/Add";
import { UploadFile } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";

function AuthorAuditScreenLayout(props) {
	return (
		<Layout>
			<Grid container style={{ backgroundColor: "#EFEFEF" }}>
				<Grid item lg={9} md={9} sm={12} mt={2} paddingLeft={7}>
					<Grid container>
						<Grid item lg={12} md={12} sm={12}>
							<Typography gutterBottom style={{ fontWeight: "700" }}>
								Question
							</Typography>
						</Grid>

						<Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
							<Controller
								control={props.control}
								name="question"
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<EditComponent
										style={{
											minHeight: "100px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											backgroundColor: "#F8F8F8",
										}}
										value={value}
										setValue={onChange}
										comment={props.comment}
										setComment={props.setComment}
										path={"question"}
									/>
								)}
							/>
						</Grid>

						<Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: 3 }}>
							<Box
								style={{
									display: "flex",
									alignItems: "center",
									justufyContent: "center",
								}}
							>
								<Typography style={{ fontWeight: "700" }}>Options</Typography>
								<IconButton onClick={() => props.optionAppend("Add Option...")}>
									<AddIcon style={{ color: "#3D76CA" }} />
								</IconButton>
							</Box>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							style={{ marginLeft: -44 }}
						>
							<List dense={true}>
								{props.optionFields?.map((value, i) => (
									<Controller
										key={i}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<Box
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
												}}
											>
												<div>
													<Checkbox
														checked={props.answer === value}
														onClick={() => {
															props.setAnswer(value);
														}}
													/>
												</div>
												<EditComponent
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														marginTop: 4,
														borderRadius: 7,
														backgroundColor: "#F4F8F4",
													}}
													value={value}
													setValue={onChange}
													comment={props.comment}
													setComment={props.setComment}
													path={`option.${i}`}
												/>
												{props.user?.userRole === "AUTHOR" && (
													<IconButton edge="end" aria-label="delete">
														<DeleteForever
															onClick={() => props.optionRemove(i)}
														/>
													</IconButton>
												)}
											</Box>
										)}
										name={`options.${i}`}
										control={props.control}
									/>
								))}
							</List>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Box
								style={{
									display: "flex",
									alignItems: "center",
									justufyContent: "center",
									marginTop: 7,
								}}
							>
								<Typography style={{ fontWeight: "700" }}>Hints</Typography>
								<IconButton
									onClick={() => {
										props.hintAppend("Add Hint...");
									}}
								>
									<AddIcon style={{ color: "#3D76CA" }} />
								</IconButton>
							</Box>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<List dense={true}>
								{props.hintFields?.map((value, i) => (
									<Controller
										key={i}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<Box
												style={{
													display: "flex",
													flexDirection: "row",
													margin: 1,
												}}
											>
												<EditComponent
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														marginTop: 4,
														borderRadius: 15,
														backgroundColor: "#F8F4F4",
													}}
													value={value}
													setValue={onChange}
													comment={props.comment}
													setComment={props.setComment}
													path={`hint.${i}`}
												/>
												{props.user?.userRole === "AUTHOR" && (
													<IconButton edge="end" aria-label="delete">
														<DeleteForever
															onClick={() => props.hintRemove(i)}
														/>
													</IconButton>
												)}
											</Box>
										)}
										name={`hints.${i}`}
										control={props.control}
									/>
								))}
							</List>
						</Grid>

						<Grid item lg={12} md={12} sm={12}>
							<Typography style={{ marginTop: 10, fontWeight: "700" }}>
								Concept Explanation
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<EditComponent
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											marginTop: 4,
											borderRadius: 13,
											backgroundColor: "#F4F4F8",
										}}
										value={value}
										setValue={onChange}
										comment={props.comment}
										setComment={props.setComment}
										path={"explanation"}
									/>
								)}
								name={`explanation`}
								control={props.control}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Typography style={{ marginTop: 20, fontWeight: "700" }}>
								Related Example
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<EditComponent
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											marginTop: 4,
											borderRadius: 13,
											backgroundColor: "#F4F4F8",
										}}
										value={value}
										setValue={onChange}
										comment={props.comment}
										setComment={props.setComment}
										path={"example"}
									/>
								)}
								name={`example`}
								control={props.control}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Typography style={{ marginTop: 20, fontWeight: "700" }}>
								Justification of Cognitive Usage
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<EditComponent
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											marginTop: 4,
											borderRadius: 13,
											backgroundColor: "#F4F4F8",
										}}
										value={value}
										setValue={onChange}
										comment={props.comment}
										setComment={props.setComment}
										path={"justification"}
									/>
								)}
								name={`justification`}
								control={props.control}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Typography style={{ marginTop: 20, fontWeight: "700" }}>
								Add Audio
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12}>
							<Card
								sx={{
									bgcolor: "#F8F4F4",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									borderRadius: "13px",
									justifyContent: "space-between",
									width: {
										xxl: "1200px",
										xl: "1150px",
										lg: "950px",
										md: "800px",
										sm: "700px",
										xs: "515px",
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<Box>
										<IconButton
											variant="contained"
											component="label"
											sx={{ ml: 2 }}
										>
											<UploadFile />
											<input type="file" hidden onChange={props.setFile} />
										</IconButton>
									</Box>
									<Typography gutterBottom component="div" sx={{ m: 2 }}>
										{!props.file
											? !!props.mediaUrl
												? "Audio File: " + props.mediaUrl.split("/").pop()
												: "Upload an audio"
											: props.file?.name.split(".")[0]}
									</Typography>
								</Box>

								{(!!props.file || !!props.mediaUrl) && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<ReactAudioPlayer
											style={{ backgroundColor: "#F8F4F4" }}
											src={
												props.file
													? URL.createObjectURL(props.file)
													: props.mediaUrl
											}
											controls
										/>
										<Box>
											<IconButton
												variant="contained"
												component="label"
												sx={{ mr: 2 }}
												onClick={() => props.setFile({ target: { files: [] } })}
											>
												<Close />
											</IconButton>
										</Box>
									</Box>
								)}
							</Card>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={3} md={3} sm={12} xs={12}>
					<DropdownBox
						style={{
							backgroundColor: "#3D76CA",
							width: "100%",
							borderBottomLeftRadius: "16px",
						}}
						setTags={props.setTags}
						tags={props.tags}
						foundationTags={props.foundationTags}
						setFoundationTags={props.setFoundationTags}
						skill={props.skill}
						setSkill={props.setSkill}
						selectedTags={props.selectedTags}
						setSelectedTags={props.setSelectedTags}
					/>

					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Grid item>
							{props.username === "author@edulabs.co.in" && (
								<Button
									style={{
										backgroundColor: "#7490C7",
										color: "white",
										marginTop: 30,
										width: "150px",
										height: "51px",
									}}
									onClick={props.handleSubmit(props.onSubmit)}
								>
									Submit
								</Button>
							)}
							{props.username === "reviewer@edulabs.co.in" && (
								<Button
									style={{
										backgroundColor: "#7490C7",
										color: "white",
										marginTop: 30,
										width: "150px",
										height: "51px",
									}}
									onClick={props.handleSubmit(props.onSubmit)}
								>
									Save
								</Button>
							)}
						</Grid>
						<Grid item ml={1}>
							<Button
								onClick={() => {
									props.history.goBack();
								}}
								style={{
									backgroundColor: "#EB5160",
									color: "white",
									marginTop: 30,
									width: "150px",
									height: "51px",
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
}

export default AuthorAuditScreenLayout;
