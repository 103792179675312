import React from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import PackageCard from "../../components/PackageCard/PackageCard";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function PackagesBlockListLayout (props) {
  const styles = useStyle();
    return (
        <Box>
          <Box className={styles.flexContainer}>
            <Typography variant="h6">Your Available Packages</Typography>
            <Button
              onClick={() => {
                props.history.push("/package/create");
              }}
            >
              Create New Assesment
            </Button>
          </Box>
          {props.isLoading ? (
            <>loading</>
          ) : props.error ? (
            <>Error</>
          ) : props.allPackages.length ? (
            <Grid container spacing={2}>
              {props.allPackages?.map((packageItem, i) => (
                <Grid item md={4} key={`packageBlock_${i}`}>
                  <PackageCard
                    id={packageItem._id}
                    title={packageItem.packageName}
                    description={`Enrolled by ${packageItem.groupId.length}  Group${
                      packageItem.groupId.length > 1 ? "s" : ""
                    }`}
                    secondary={packageItem.questions.length + " Quesions"}
                    onBtnClick={() => {
                      props.history.push(`/packages/${packageItem._id}`);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>No Packages Yet</>
          )}
        </Box>
      );
}

export default PackagesBlockListLayout;