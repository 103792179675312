import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SubjectContext } from "../../contexts/subjectContext";
import DashboardLayout from "../../designs/AddQuestions/DashboardLayout";
import useItemsHelper from "../../hooks/generic/useItems";
import { uniq } from "lodash";

export const Dashboard = () => {
	const history = useHistory();
	const [tags, setTags] = useState();
	const [selectedItems, setSelectedItems] = useState([]);
	const [group, setGroup] = useState("");
	const { updateItem } = useItemsHelper();
	const [selectedTags, setSelectedTags] = useState({
		grades: [],
		strand: [],
		domain: [],
		topic: [],
		concept: [],
	});
	useEffect(() => {
		console.log(selectedItems);
	}, [selectedItems]);

	useEffect(() => {
		let storedTags = localStorage.getItem("CLASS");
		if (storedTags) {
			setSelectedTags(
				{
					grades: [storedTags],
					strand: [],
					domain: [],
					topic: [],
					concept: [],
				}
			)
		}
	}, [])

	const user = useSelector((state) => state.user.user);

	const isAuthor = useMemo(() => {
		return user?.userRole === "AUTHOR";
	}, [user]);

	const groupItems = async () => {
		const promises = [];
		console.log(selectedItems);
		selectedItems.forEach((item) => {
			let groups = [];
			if (item?.groups) {
				groups = item.groups;
			}
			groups.push(group);
			item.groups = uniq(groups);
			console.log(item);
			promises.push(updateItem(item?._id, item));
		});
		Promise.all(promises);
	};

	const { subject } = useContext(SubjectContext);

	return (
		<DashboardLayout
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setTags={setTags}
			setGroup={setGroup}
			group={group}
			groupItems={groupItems}
			tags={tags}
			isAuthor={isAuthor}
			subject={subject}
			history={history}
			selectedTags={selectedTags}
			setSelectedTags={setSelectedTags}
		/>
	);
};
