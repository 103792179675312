import React from "react";
import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import { CustomInput } from "../../components/CustomInput/CustomInput";

export let tagValues = [
	"Mock-01",
	"Mock-02",
	"Mock-03",
	"Mock-04",
	"LiveOlympiad Final",
	"L-2",
	"Screening",
	"Practice",
];
function NewPackageLayout(props) {
	return (
		<Box
			component="form"
			onSubmit={(e) => {
				e.preventDefault();
			}}
			noValidate
			autoComplete="off"
			padding={5}
		>
			<Box
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<FormControl
					variant="standard"
					style={{
						marginBottom: "2rem",
					}}
				>
					<div>
						<Typography variant="subtitle2" gutterBottom component="div">
							Assesment Name
						</Typography>
						<CustomInput
							value={props.assessmentName}
							onChange={(e) => {
								props.setPackageName(e.target.value);
							}}
						/>
					</div>
					<div style={{ display: "flex", margin: "1.5rem 0" }}>
						<div style={{ marginRight: "25px" }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Class
							</Typography>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={props.classNames}
								label="Age"
								style={{ minWidth: "150px" }}
								onChange={(e) => props.setClassNames(e.target.value)}
							>
								{props.groups?.data?.data.map((classObj) => (
									<MenuItem key={classObj.id} value={classObj.groupName}>
										{classObj.groupName}
									</MenuItem>
								))}
							</Select>
						</div>
						<div style={{ marginRight: "25px" }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Subject
							</Typography>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={props.subjects}
								label="Subject"
								style={{ minWidth: "150px" }}
								onChange={(e) => props.setSubjects(e.target.value)}
							>
								{props.categories &&
									Object.keys(props.categories?.data).map((key) => (
										<MenuItem value={key}>{key}</MenuItem>
									))}
							</Select>
						</div>
						<div style={{ marginRight: "25px" }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Series
							</Typography>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={props.difficulty}
								label="Difficulty"
								style={{ minWidth: "150px" }}
								onChange={(e) => props.setDifficulty(e.target.value.trim())}
							>
								{tagValues?.map((value, i) => (
									<MenuItem key={`select-ans-${i}`} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</div>
					</div>
					<div style={{ display: "flex", margin: "1.5rem 0" }}>
						<div style={{ marginRight: "25px" }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Total Duration in minutes
							</Typography>
							<CustomInput
								value={props.totalDurationMin}
								width="200px"
								type="number"
								onChange={(e) => {
									props.setTotalDurationMin(e.target.value);
								}}
							/>
						</div>
						<div style={{ marginRight: "25px" }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Total Marks
							</Typography>
							<CustomInput
								value={props.totalMarks}
								type="number"
								width="200px"
								onChange={(e) => {
									props.setTotalMarks(e.target.value);
								}}
							/>
						</div>
					</div>
				</FormControl>
				<Button onClick={props.handleSubmit} variant="contained">
					Submit
				</Button>
			</Box>
		</Box>
	);
}

export default NewPackageLayout;
