import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const authApi = createApi({
	reducerPath: "authApi",
	tagTypes: ["Auth"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/auth`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		createBulkStudents: builder.mutation({
			query: (data) => ({
				url: `/register/bulk`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Auth"],
		}),
	}),
});

// export const { trigger, lastArg } =
//   profileApi.endpoints.getProfile.useLazyQuerySubscription();

export const { useCreateBulkStudentsMutation } = authApi;
