import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const tagsApi = createApi({
	reducerPath: "TagsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/tags`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Tags"],
	endpoints: (builder) => ({
		getTags: builder.query({
			query: () => ({
				url: `/`,
			}),
		}),
		findTags: builder.query({
			query: (searchTerm) => ({
				url: `/search`,
				method: "POST",
				body: { searchTerm: searchTerm },
			}),
		}),
	}),
});

export const { useGetTagsQuery, useLazyFindTagsQuery } = tagsApi;
