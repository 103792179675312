import React from "react";
import './LoginPage.css';
import {
    Box,
    Button,
    Grid,
    Input
  } from "@material-ui/core";
import { InputBase, Stack, Typography } from "@mui/material";

function LoginPageLayout (props) {
    return (
		<Grid
			container
			style={{ backgroundColor: "#E5F0FF", width: "100%", height: "100%" }}
		>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Grid container className="login">
					<img src="Images/blank.png" className="height_100" width="100%" />
					<Box
						sx={{
							top: "30px",
							left: "100px",
							position: "absolute",
							zIndex: 1,
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<img src="Images/EduLabs.png" height="50%" width="60%" />
							<img
								src="Images/cms.png"
								height="9%"
								width="11%"
								className="logo"
							/>
						</Box>
					</Box>

					<Box
						style={{
							position: "absolute",
							zIndex: 1,
							backgroundColor: "#E5F0FF",
							width: "50%",
							height: "80%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: "20px",
						}}
					>
						<Stack
							direction="column"
							sx={{
								display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
							}}
						>
							<Typography
								style={{
									fontFamily: "Poppins",
									fontWeight: 700,
									fontSize: "20px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								Login to Edulabs CMS
							</Typography>

              <Typography
                style={{
                  marginTop: 20,
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "16px",
                  /* identical to box height */
                  color: "#184877",
                }}
              >
                Email
              </Typography>
              <Box style={{ display: "flex" }}>
                <InputBase
                  type="text"
                  placeholder="Your email name"
                  className="login__input"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "329px",
                    height: "47px",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: " 9px",
                  }}
                />

                <Typography
                  style={{
                    marginLeft: 6,
                    marginTop: 16,
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#184877",
                  }}
                >
                  @edulabs.co.in
                </Typography>
              </Box>
              <Box style={{ marginTop: 30 }}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: "16px",
                    /* identical to box height */
                    color: "#184877",
                  }}
                >
                  Password
                </Typography>
                <InputBase
                  type="password"
                  placeholder="Your password"
                  className="login__inputi"
                  value={props.password}
                  onChange={(e) => props.setPassword(e.target.value)}
                  style={{ width: "450px", height: "47px" }}
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",

                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: " 9px",
                  }}
                />
              </Box>
              <Box style={{ marginTop: 40, marginLeft: 22 }}>
                <Button
                  style={{
                    backgroundColor: "teal",
                    border: "none",
                    color: "white",
                    padding: "5px",
                    cursor: "pointer",
                    width: "437px",
                    height: "53px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    backgroundColor: "#1972F5",
                    borderRadius: "46px",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                  onClick={props.handleSubmit}
                  disabled={props.isFetching}
                >
                  Login
                </Button>
              </Box>
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: { lg: "none", md: "none", sm: "none", xs: "flex" },
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Login to CMS
              </Typography>

              <Typography
                style={{
                  marginTop: 20,
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "16px",
                  /* identical to box height */
                  color: "#184877",
                }}
              >
                Email
              </Typography>
              <Box style={{ display: "flex" }}>
                <Input
                  type="text"
                  placeholder="Your email name"
                  className="login__inputsm"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  inputProps={{
                    sx: {
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "329px",
                      height: "47px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                      borderRadius: " 9px",
                    },
                  }}
                />

                <Typography
                  style={{
                    marginLeft: 6,
                    marginTop: 16,
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#184877",
                  }}
                >
                  @edulabs.co.in
                </Typography>
              </Box>
              <Box style={{ marginTop: 30 }}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: "16px",
                    /* identical to box height */
                    color: "#184877",
                  }}
                >
                  Password
                </Typography>
                <Input
                  type="password"
                  placeholder="Your password"
                  className="login__inputism"
                  value={props.password}
                  onChange={(e) => props.setPassword(e.target.value)}
                  inputProps={{
                    sx: {
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "329px",
                      height: "47px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                      borderRadius: " 9px",
                    },
                  }}
                />
              </Box>
              <Box style={{ marginTop: 80, marginLeft: 22 }}>
                <Button
                  style={{
                    backgroundColor: "teal",
                    border: "none",
                    color: "white",
                    padding: "5px",
                    cursor: "pointer",
                    width: "137px",
                    height: "53px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,

                    backgroundColor: "#1972F5",
                    borderRadius: "46px",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                  onClick={props.handleSubmit}
                  disabled={props.isFetching}
                >
                  Login
                </Button>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginPageLayout;