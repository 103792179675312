import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../redux/auth/apiCall";
import LoginPageLayout from "../../designs/login/LoginPageLayout";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.user.isFetching);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await Login(
      { email: `${email}@edulabs.co.in`, password, loginForced: true },
      dispatch
    );
  };

	return (
    <LoginPageLayout
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleSubmit={handleSubmit}
        isFetching={isFetching}
    />
  )
}

export default LoginPage;
