import React from "react";
import { Comment } from "@mui/icons-material";
import {
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	Grid,
	IconButton,
	Paper,
	Popover,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DialogBox } from "../../pages/NewQuestion/DialogBox";
import parse from "html-react-parser";

function EditComponentLayout (props) {
    return (
		<div>
			<Card
				style={props.style}
				sx={{
					width: {
						xxl: "1200px",
						xl: "1150px",
						lg: "950px",
						md: "800px",
						sm: "700px",
						xs: "515px",
					},
				}}
			>
				<Grid container style={{ marginLeft: 31 }}>
					<Grid item lg={11.3} md={11.3} style={{ marginTop: 10 }}>
						<Typography
							gutterBottom
							variant="h6"
							color="#9B9BAF"
							onClick={() => props.isAuthor && props.setOpen(true)}
							sx={{ cursor: props.isAuthor ? "pointer" : "" }}
						>
							{parse(props.value ?? "")}
						</Typography>
					</Grid>
					<Grid item lg={0.7} md={0.7}>
						<IconButton edge="end" aria-label="comment" onClick={props.handleClick}>
							<Badge
								color="primary"
								badgeContent={props.comment[props.path]?.length > 0 ? "" : 0}
								variant="dot"
							>
								<Comment />
							</Badge>
						</IconButton>
					</Grid>
				</Grid>
			</Card>
			<Popover
				id={props.id}
				open={props.commentOpen}
				anchorEl={props.anchorEl}
				onClose={props.handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Paper sx={{ p: 1 }}>
					<Typography style={{ fontWeight: "700" }}>Comments</Typography>
					<Box py={1}>
						{props.comment[props.path]?.map((commentVal) => (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									gap: "1px",
									p: 1,
									my: -2,
								}}
							>
								<Avatar
									sx={{
										width: 32,
										height: 32,
										backgroundColor: "#3D76CA",
										mt: 1,
										fontSize: 16,
									}}
								>
									{commentVal?.commenter
										.split(" ")
										.map((str) => str[0])
										.join("")}
								</Avatar>
								<Box sx={{ p: 1 }}>
									<Typography fontSize={12} fontWeight={400}>
										{commentVal?.commenter}
									</Typography>
									<Typography
										fontStyle={"italic"}
										fontWeight={200}
										fontSize={12}
									>
										{commentVal?.body}
									</Typography>
								</Box>
							</Box>
						))}
					</Box>
					<TextField
						multiline
						value={props.commentValue}
						onChange={(e) => props.setCommentValue(e.target.value)}
					/>
					<Stack direction={"row"} justifyContent={"center"} gap={1} m={1}>
						<Button
							size="small"
							variant="contained"
							onClick={props.changeComment}
							sx={{ borderRadius: "20px" }}
						>
							Save
						</Button>
						<Button
							variant="contained"
							sx={{ borderRadius: "20px" }}
							onClick={() => {
								props.setCommentValue("");
								props.handleClose();
							}}
						>
							Cancel
						</Button>
					</Stack>
				</Paper>
			</Popover>
			<DialogBox
				path={props.path}
				click={props.open}
				setClick={props.setOpen}
				data={props.value}
				setData={props.setValue}
			/>
		</div>
	);
}

export default EditComponentLayout;