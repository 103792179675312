import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const itemsApi = createApi({
	reducerPath: "itemsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.itemsURL}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Items"],
	endpoints: (builder) => ({
		getIdForNewItem: builder.query({
			query: () => `/availableQuestion`,
			transformResponse: (response, meta, arg) => {
				return response.status ? response.id : "null";
			},
		}),
		getAllItems: builder.query({
			query: () => `/`,
			providesTags: ["Items"],
		}),
		createItem: builder.mutation({
			query: (data) => ({
				url: `/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Items"],
		}),

		editItem: builder.mutation({
			query: ({ id, ...data }) => {
				return {
					url: `/${id}`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["Items"],
		}),

		deleteItem: builder.mutation({
			query: (id) => ({
				url: `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Items"],
		}),

		addMedia: builder.mutation({
			query: (data) => ({
				url: `/media`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Media"],
		}),
		getMedia: builder.mutation({
			query: (id) => ({
				url: `/media/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Media"],
		}),
	}),
});

export const {
	useLazyGetIdForNewItemQuery,
	useGetAllItemsQuery,
	useCreateItemMutation,
	useDeleteItemMutation,
	useEditItemMutation,
	useAddMediaMutation,
	useGetMediaMutation,
} = itemsApi;
