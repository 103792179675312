import React from "react";
import { useState } from "react";
import FilterBoxLayout from "../../designs/PackagesBlockList/FilterBoxLayout";
import useAdminHelper from "../../hooks/generic/useAdminHelper";
import { useQuery } from "react-query";
import useItemsHelper from "../../hooks/generic/useItems";

// const Filter = [
//   { name: "Grade", select: "Grade", class: "01" },
//   { name: "Subject", select: "Subject", class: "Mathematics" },
//   {
//     name: "Delivery Type",
//     select: " Delivery Type",
//     class: "Mock-01",
//   },
// ];
// const Menu = [
//   { class: ["class-01", "class-02"] },
//   { subject: ["mathematics", "science"] },
//   { subject: ["mathematics", "science"] },
// ];

const FilterBox = (props) => {
	const [selected, setSelected] = useState();

	const { getGroups } = useAdminHelper();
	const { data: groups, status } = useQuery(["groups"], getGroups);

	const { getCategories } = useItemsHelper();
	const { data: categories } = useQuery(["categories"], getCategories);

	const tests = [
		"Practice",
		"Mock-01",
		"Screening",
		"LiveOlympiad Final",
		"Mock-02",
		"Mock-03",
		"Mock-04",
		"L-1",
		"L-2",
	];

	return (
		<FilterBoxLayout
			//Filter={Filter}
			groups={groups}
			categories={categories}
			subject={props.subject}
			setSubject={props.setSubject}
			series={props.series}
			setSeries={props.setSeries}
			grade={props.grade}
			setGrade={props.setGrade}
			tests={tests}
			handleFilter={props.handleFilter}
			handleReset={props.handleReset}
		/>
	);
};

export default FilterBox;
