import React from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import QuestionsTable from "../../components/QuestionsTable/QuestionsTable";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { NotificationDialog } from "../../components/Notification/NotificationDialog";
import FilterBox from "../../pages/PackagesBlockList/FilterBox";
import DomainBox from "../../pages/PackagesBlockList/DomainBox";
import Layout from "../../pages/layout/Layout";

function PackagesLayout(props) {
	return (
		<Layout>
			<Grid container spacing={2} style={{ backgroundColor: "#EFEFEF" }}>
				<Grid item lg={3} style={{ marginTop: "15px" }}>
					{props.subjects.length > 0 && (
						<DomainBox
							domain={props.domain}
							setDomain={props.setDomain}
							subjects={props.subjects}
						/>
					)}
				</Grid>
				<Grid item lg={9} style={{ marginTop: "15px" }}>
					<Grid
						item
						lg={12}
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								style={{
									width: "617px",
									height: "58px",
									backgroundColor: "#FFFFFF",
									border: "1px solid #CAC2C2",
									borderRadius: "12px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									style={{
										width: "145px",
										height: "40px",
										backgroundColor: "#77ADFC",
										borderRadius: "5px",
									}}
								>
									<Typography
										style={{
											fontStyle: "normal",
											fontWeight: 700,
											fontSize: "12px",
											color: "#FFFFFF",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											paddingTop: 10,
										}}
									>
										Subject:{props.subjects}
									</Typography>
								</Box>
								<Box
									style={{
										width: "114px",
										height: "40px",
										backgroundColor: "#77ADFC",
										borderRadius: "5px",
										marginLeft: 2,
									}}
								>
									<Typography
										style={{
											fontStyle: "normal",
											fontWeight: 700,
											fontSize: "12px",
											color: "#FFFFFF",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											paddingTop: 10,
										}}
									>
										Grade: {props.classNames}
									</Typography>
								</Box>
								<Box
									style={{
										width: "154px",
										height: "40px",
										backgroundColor: "#77ADFC",
										borderRadius: "5px",
										marginLeft: 2,
									}}
								>
									<Typography
										style={{
											fontStyle: "normal",
											fontWeight: 700,
											fontSize: "12px",
											color: "#FFFFFF",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											paddingTop: 10,
										}}
									>
										Delivery Type: {props.series}
									</Typography>
								</Box>
								<Box
									style={{
										width: "87px",
										height: "40px",
										backgroundColor: "#77ADFC",
										borderRadius: "5px",
										marginLeft: 2,
									}}
								>
									<Typography
										style={{
											fontStyle: "normal",
											fontWeight: 700,
											fontSize: "12px",
											color: "#FFFFFF",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											paddingTop: 10,
										}}
									>
										Time:{props.totalDurationMin} Min
									</Typography>
								</Box>
							</Box>
							<Box
								style={{
									width: "181px",
									height: "58px",
									backgroundColor: "#FFFFFF",
									border: "1px solid #CAC2C2",
									borderRadius: "12px",
									marginLeft: 8,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography
										style={{
											fontStyle: "Inter",
											fontWeight: 700,
											fontSize: "12px",
											color: "#CAC2C2",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										Questions
									</Typography>
									<Box
										style={{
											width: "60px",
											height: "40px",
											backgroundColor: "#77ADFC",
											borderRadius: "5px",
											marginLeft: 5,
										}}
									>
										<Typography
											style={{
												fontStyle: "normal",
												fontWeight: 700,
												fontSize: "16px",
												color: "#FFFFFF",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												paddingTop: 10,
											}}
										>
											{props.ques.length}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "flex-end",
								}}
							>
								<Button
									style={{
										width: "134px",
										height: "50px",
										backgroundColor: "#3D76CA",
										boxShadow: "0px 6px 7px rgba(0, 0, 0, 0.25)",
										borderRadius: "6px",
										marginLeft: 8,
										color: "#FFFFFF",
										fontFamily: "Inter",
										fontWeight: 700,
										fontSize: "16px",
									}}
									onClick={props.handleSubmit}
								>
									Add Items
								</Button>
							</Box>
						</Box>
					</Grid>
					<Grid item lg={12}>
						<Box style={{ width: "980px" }}>
							{props.subjects !== undefined &&
								props.classNames !== undefined &&
								props.domain !== undefined &&
								props.subjects.length > 0 &&
								props.classNames.length > 0 &&
								props.domain.length > 0 && (
									<QuestionsTable
										subjects={props.subjects}
										classNames={props.calculatedGrades}
										selectedItems={props.selectedItems}
										setSelectedItems={props.setSelectedItems}
										domain={props.domain}
									/>
								)}
						</Box>
						<Box style={{ marginTop: 15, marginLeft: 15 }}>
							<MenuBookIcon onClick={() => props.setOpen(true)} />
						</Box>
					</Grid>
				</Grid>
				<NotificationDialog click={props.open} setClick={props.setOpen} />
			</Grid>
		</Layout>
	);
}

export default PackagesLayout;
