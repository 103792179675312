import { Box, Grid } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import {
	Avatar,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	ListItemIcon,
	Menu,
	MenuItem,
	Select,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/auth/authSlice";
import Logout from "@mui/icons-material/Logout";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { startCase } from "lodash";
import { SubjectContext } from "../contexts/subjectContext";
import { useQuery } from "react-query";
import useItemsHelper from "../hooks/generic/useItems";
import { useLocation } from "react-router-dom";

export const Header = () => {
	const [ope, setOpe] = useState();
	const location = useLocation();
	const dispatch = useDispatch();
	const logoutToApp = () => {
		dispatch(logout());
	};
	const user = useSelector((state) => state.user.user);
	const { subject, setSubject } = useContext(SubjectContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { getCategories } = useItemsHelper();
	const { data: categories } = useQuery(["categories"], getCategories);

	const heading = (pathName) => {
		let heading = "";
		if (pathName === "/") {
			heading = "Items";
		} else if (pathName === "/question/create") {
			heading = "Create Item";
		} else if (pathName.includes("/question/update")) {
			heading = "Edit Item";
		}
		return heading;
	};
	var userData = localStorage.getItem("user");
	var userObject = JSON.parse(userData);
	var username = userObject.email;
	return (
		<Grid
			container
			style={{
				backgroundColor: "#FFFCFC",
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
				width: "100%",
				padding: 3,
			}}
		>
			<Grid item lg={10} md={10}>
				<Grid container>
					<Grid item style={{ marginLeft: "1%" }}>
						<Box
							style={{
								backgroundColor: "#3D76CA",
								width: 50,
								height: 50,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								margin: 2,
								borderRadius: "25px",
							}}
						>
							{" "}
							<img src="/Images/loOlympiad.png" />
						</Box>
					</Grid>
					<Grid item style={{ marginTop: 18 }}>
						{/* <img src="./images/dashboard.png" /> */}
						<Typography fontWeight={700}>
							&nbsp; CMS {`: ${heading(window.location.pathname)}`}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item lg={2} md={2}>
				<Box display={"flex"} flexDirection={"row"}>
					{((username!=="packager@edulabs.co.in" && location.pathname==="/") || location.pathname==="/dashboard") && (<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
						<InputLabel
							id="demo-simple-select-standard-label"
							sx={{ fontWeight: 600 }}
						>
							Subjects
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
							label="Subjects"
						>
							{categories &&
								Object.keys(categories?.data).map((key) => (
									<MenuItem value={key}>{key}</MenuItem>
								))}
						</Select>
					</FormControl>)}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							textAlign: "center",
							justifyContent: "flex-end",
						}}
					>
						<Tooltip title="Profile">
							<IconButton
								onClick={handleClick}
								size="small"
								sx={{ ml: 2 }}
								aria-controls={open ? "account-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
							>
								<Avatar
									sx={{ width: 40, height: 40, backgroundColor: "#3D76CA" }}
								>
									{user?.fullName
										.split(" ")
										.map((str) => str[0])
										.join("")}
								</Avatar>
							</IconButton>
						</Tooltip>
					</Box>
					<Menu
						anchorEl={anchorEl}
						id="account-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						<Stack direction="column" px={2} pb={1}>
							<Typography
								style={{
									display: "flex",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									fontWeight: 700,
									fontSize: "24px",
									color: "#000000",
								}}
							>
								{user?.fullName}
							</Typography>
							<Typography
								style={{
									display: "flex",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									color: "#000000",
								}}
							>
								{startCase(user?.userRole.toLowerCase())}
							</Typography>
						</Stack>
						<Divider />
						<MenuItem
							onClick={() => setOpe(true)}
							style={{
								fontFamily: "Inter",
								color: "#706D6D",
							}}
						>
							<ListItemIcon>
								<ManageAccountsIcon fontSize="small" />
							</ListItemIcon>
							Change Password
						</MenuItem>
						<MenuItem
							onClick={logoutToApp}
							style={{
								fontFamily: "Inter",
								color: "#706D6D",
							}}
						>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							Logout
						</MenuItem>
					</Menu>
				</Box>
			</Grid>
			<ChangePassword click={ope} setClick={setOpe} />
		</Grid>
	);
};
