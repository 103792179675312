import axiosInstance from "../../redux/apis/api.service";
import { environment } from "../../environments/environment";
const useItemsHelper = () => {
	const getItems = async (tags, subject, page, pageSize,domain) => {
		if(domain) {
			tags = [...tags, subject, domain];
		}
		else if (tags) {
			tags = [...tags, subject];
		} else {
			tags = [subject];
		}
		let endpoint = `${
			environment.itemsURL
		}/search?page=${page}&limit=${pageSize}&tags=${tags.join(",")}`;
		return await axiosInstance().get(endpoint);
	};

	const getItem = async (itemId) => {
		let endpoint = `${environment.itemsURL}/${itemId}`;
		return await axiosInstance().get(endpoint);
	};

	const createItem = async (body) => {
		let endpoint = `${environment.itemsURL}`;
		return await axiosInstance().post(endpoint, body);
	};

	const addMedia = async (body) => {
		let endpoint = `${environment.itemsURL}/media`;
		return await axiosInstance().post(endpoint, body);
	};

	const getMedia = async (questionId) => {
		let endpoint = `${environment.itemsURL}/media/${questionId}`;
		return await axiosInstance().get(endpoint);
	};

	const updateItem = async (itemId, body) => {
		let endpoint = `${environment.itemsURL}/${itemId}`;
		return await axiosInstance().put(endpoint, body);
	};

	const getCategories = async (queryObj) => {
		let endpoint = `${environment.itemsURL}/scope`;
		if (queryObj?.queryKey.length > 1 && queryObj?.queryKey[1]) {
			endpoint = `${endpoint}?subject=${queryObj?.queryKey[1]}`;
		}
		return await axiosInstance().get(endpoint);
	};

	return {
		getItems,
		createItem,
		updateItem,
		getItem,
		getCategories,
		addMedia,
		getMedia,
	};
};

export default useItemsHelper;
