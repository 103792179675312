import {
	Box,
	Card,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	TableContainer,
	TableFooter,
	Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MathJax from "mathjax3-react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
	ArrowDownward,
	ArrowUpward,
	CompareArrowsOutlined,
	DeleteOutline,
	EditOutlined,
	FirstPage,
	LastPage,
	NavigateBefore,
	NavigateNext,
	Search,
	Close,
} from "@material-ui/icons";
import CommentIcon from "@mui/icons-material/Comment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	useExpanded,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table";
import { CustomizeToastError } from "../Toasts/Toasts";
import TableCheckbox from "./TableCheckbox";
import Fuse from "fuse.js";
import { Tooltip } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	tableWrap: {
		// display: "block",
		marginTop: "1rem",
		maxWidth: "98%",
		overflowX: "scroll",
		overflowY: "hidden",
	},
	rotated: {
		transform: "rotate(90deg)",
	},
	paginationContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	cells: {
		margin: 0,
		padding: "0.2rem",
		"&.collapse": {
			// width: "0.0000000001%",
		},
		"&:last-child": {
			borderRight: 0,
		},
	},
}));
function BasicTable({
	tableType = "QUESTIONS",
	columns,
	data,
	total,
	handleDelete,
	showActions = true,
	needExpander = true,
	onRowsSelect,
	page,
	pageSize,
	setPage,
	setPageSize,
	disableRows = [],
}) {
	const styles = useStyles();
	const history = useHistory();

	const memoedColumns = useMemo(() => [...columns], []);
	const query = new URLSearchParams(window.location.search);
	const tagsP = query.get("tags");

	const user = useSelector((state) => state.user.user);

	const isAuthor = useMemo(() => {
		return user?.userRole === "AUTHOR";
	}, [user]);

	const {
		getTableProps,
		headerGroups,
		prepareRow,
		page: rows,
		getRowModel,
		visibleColumns,
		selectedFlatRows,
	} = useTable(
		{
			columns: memoedColumns,
			data: data,
		},
		// useResizeColumns,
		// useAbsoluteLayout,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,

		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				let finalColumns = [
					// Let's make a column for selection
					{
						id: "selection",
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						minWidth: 20,
						width: 50,
						maxWidth: 50,
						Header: ({ getToggleAllPageRowsSelectedProps }) => (
							<div>
								{disableRows.length ? null : (
									<TableCheckbox {...getToggleAllPageRowsSelectedProps()} />
								)}
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<TableCheckbox
									disabled={
										!!disableRows.length &&
										disableRows.includes(row.original._id)
									}
									{...row.getToggleRowSelectedProps()}
								/>
							</div>
						),
					},
					...columns,
				];
				if (showActions) {
					finalColumns.push({
						id: "action",
						Header: () => (
							<TableCell
								size="small"
								padding="none"
								align="center"
								style={{
									padding: "0px 5px",
									position: "relative",
									border: "none",
								}}
							>
								Action
							</TableCell>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<TableCell style={{ display: "flex" }}>
								<IconButton
									onClick={() => {
										if (tableType === "QUESTIONS") {
											history.push(
												`/question/update/${row.original?._id}?tags=${tagsP}`
											);
										}
									}}
								>
									{isAuthor ? (
										<EditOutlined fontSize="small" />
									) : (
										<CommentIcon fontSize="small" />
									)}
								</IconButton>
								{isAuthor && (
									<IconButton
										onClick={() => {
											if (tableType === "QUESTIONS") {
												if (row.original.inUse) {
													return CustomizeToastError(
														`Question is being used in assessments and cannot be deleted`
													);
												}
											}
											handleDelete(row.original?._id);
										}}
									>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</TableCell>
						),
					});
				}
				return finalColumns;
			});
		}
	);
	useEffect(() => {
		if (typeof onRowsSelect === "function") {
			let originalRows = selectedFlatRows.map((row) => row?.original._id);
			onRowsSelect(originalRows);
		}
	}, [selectedFlatRows]);
	// Render the UI for your table
	return (
		<>
			<div className={styles.tableWrap}>
				<MaUTable {...getTableProps()}>
					<TableContainer component={Paper}>
						<TableHead>
							{headerGroups.map((headerGroup, i) => (
								<TableRow
									key={`table-row-${i}`}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map((column, j) => (
										<TableCell
											key={`table-header-cell-${i}_${j}`}
											size="small"
											padding="none"
											{...column.getHeaderProps(column.getSortByToggleProps())}
											align="center"
											style={{
												padding: "0px 10px",
												position: "relative",
												minWidth:
													column.id === "question"
														? "clamp(10rem, 25rem, 30rem)"
														: "auto",
												// flex: column.id === "question" ? 1 : 0,
											}}
											className={styles.cells}
										>
											<div
												style={{ display: "flex", justifyContent: "center" }}
											>
												<div style={{ whiteSpace: "nowrap" }}>
													{column.render("Header")}
												</div>
												{column.id !== "selection" &&
												column.id !== "expander" &&
												column.id !== "action" ? (
													<div>
														<IconButton size="small">
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<ArrowDownward fontSize="small" />
																) : (
																	<ArrowUpward fontSize="small" />
																)
															) : (
																<CompareArrowsOutlined
																	fontSize="small"
																	className={styles.rotated}
																/>
															)}
														</IconButton>
													</div>
												) : null}
											</div>
										</TableCell>
									))}
								</TableRow>
							))}
						</TableHead>
						<TableBody>
							{rows.map((row, i) => {
								prepareRow(row);
								return (
									<>
										<TableRow
											{...row.getRowProps()}
											key={`row_${row.original._id}_${i}`}
										>
											{row.cells.map((cell, j) => {
												return (
													<TableCell
														key={`row-cell-${i}_${j}`}
														size="small"
														padding="none"
														{...cell.getCellProps()}
														style={{ padding: "0px 5px" }}
														className={styles.cells}
													>
														{cell.render("Cell")}
													</TableCell>
												);
											})}
										</TableRow>
										{row.isExpanded && (
											<TableRow
												key={`row_${row.original._id}_${i}`}
												{...row.getRowProps()}
												style={{ transition: ".5s ease" }}
											>
												<TableCell
													colSpan={visibleColumns.length - 2}
													size="small"
												>
													<Grid container>
														<Grid item sm={12}>
															<Typography gutterBottom>Options</Typography>
														</Grid>
														{row?.original?.options?.map((option, i) => (
															<Grid key={`option-cell-${i}`} item sm={6}>
																<Card
																	sx={{
																		backgroundColor: `${
																			option === row?.original?.answer
																				? "#c1d7c1"
																				: "#f4f8f4"
																		}`,
																		m: 1,
																		borderRadius: 2,
																	}}
																>
																	<Box sx={{ display: "flex" }}>
																		<Typography
																			sx={{
																				m: 2,
																			}}
																		>
																			{String.fromCharCode(97 + i) + ")"}
																		</Typography>
																		<Typography
																			sx={{
																				m: 2,
																			}}
																		>
																			<MathJax.Html html={option} />
																		</Typography>
																	</Box>
																</Card>
															</Grid>
														))}
													</Grid>
													{/* </Collapse> */}
												</TableCell>
											</TableRow>
										)}
									</>
								);
							})}
						</TableBody>
					</TableContainer>
				</MaUTable>
				<TablePagination
					component="div"
					count={total}
					page={page}
					onPageChange={(e, newPage) => setPage(newPage)}
					rowsPerPage={pageSize}
					onRowsPerPageChange={(e) => {
						setPageSize(parseInt(e.target.value, 10));
						setPage(0);
					}}
				/>
			</div>
		</>
	);
}

export default BasicTable;
