import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@material-ui/core";
import {
  useAddQusToPackageMutation,
  useDeleteQusFromPackageMutation,
  useGetPackageDetailsQuery,
} from "../../redux/apis/packagesApi";
import BasicTable from "../Table/BasicTable";
import QuestionsTable from "../QuestionsTable/QuestionsTable";
import MathJax from "mathjax3-react";
import usePackages from "../../hooks/generic/usePackages";

const QuestionsByPackageTable = ({ packageId }) => {
  const { packageId: routerPackageId } = useParams();
  const {getPackageById} = usePackages();
  const {
    data: ddata,
    error,
    isLoading,
  } = getPackageById(routerPackageId || packageId);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedNewQuestions, setSelectedNewQuestions] = useState([]);
  const [addQuestionsMode, setAddQuestionsMode] = useState(false);
  const [deleteQusestions] = useDeleteQusFromPackageMutation();
  const [addQusestions] = useAddQusToPackageMutation();
  const handleDeletion = () => {
    deleteQusestions({
      id: ddata._id,
      questions: selectedQuestions.map((qus) => ({
        id: qus.original._id,
        durationMin: 0,
        marks: 0,
      })),
    });
  };
  const handleAddition = async () => {
    await addQusestions({
      id: ddata._id,
      questions: selectedNewQuestions.map((qus) => ({
        id: qus.original._id,
        durationMin: 0,
        marks: 0,
      })),
    });
    setAddQuestionsMode(false);
  };
  const itemColumns = [
    // {
    //   Header: "Id",
    //   accessor: "_id",
    // },
    {
      Header: "Question",
      accessor: "question",
      // minWidth: 600,
      // width: 200,
      Cell: ({ row }) => (
        <Box sx={{ width: "clamp(400px, 600px, 800px)" }}>
          <MathJax.Html html={row.original.question} />
        </Box>
      ),
    },
    {
      Header: "Answer",
      accessor: "answer",
      Cell: ({ row }) => (
        <Box sx={{ width: "clamp(400px, 600px, 800px)" }}>
          <MathJax.Html html={row.original.answer} />
        </Box>
      ),
    },
    {
      Header: "Created By",
      accessor: "creatorName",
    },
  ];
  return !addQuestionsMode ? (
    <Box>
      {isLoading ? (
        <>Loading</>
      ) : error ? (
        <>Error !!</>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle" gutterBottom component="div">
              Package Name: {ddata?.packageName}
            </Typography>
            <div>
              {/* <Fab disabled aria-label="like" variant="extended">
                <Delete />
              </Fab> */}
              <Button
                disabled={selectedQuestions.length === 0}
                onClick={handleDeletion}
              >
                Delete Questions
              </Button>
              <Button
                onClick={() => {
                  setAddQuestionsMode(true);
                }}
              >
                Add Questions
              </Button>
            </div>
          </Box>
          <BasicTable
            data={ddata?.questions}
            onRowsSelect={setSelectedQuestions}
            columns={itemColumns}
            showActions={false}
          />
        </>
      )}
    </Box>
  ) : (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem 0",
        }}
      >
        <Button
          variant="contained"
          style={{ width: "70px", marginRight: ".6rem" }}
          onClick={handleAddition}
        >
          Add
        </Button>
        <Button
          style={{ width: "70px" }}
          variant="contained"
          onClick={() => setAddQuestionsMode(false)}
        >
          Cancel
        </Button>
      </Box>
      <QuestionsTable
        onRowsSelect={setSelectedNewQuestions}
        disableRows={ddata?.questions?.map((qus) => qus._id)}
        showActions={false}
      />
    </Box>
  );
};

export default QuestionsByPackageTable;
