import React from "react";
import { useState, useEffect } from "react";
import PackageDialogLayout from "../../designs/PackagesBlockList/PackageDialogLayout";
import usePackages from "../../hooks/generic/usePackages";
import useAdminHelper from "../../hooks/generic/useAdminHelper";
import useItemsHelper from "../../hooks/generic/useItems";
import { useQuery } from "react-query";
import {
	CustomizeToastError,
	CustomizeToastSuccess,
} from "../../components/Toasts/Toasts";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const PackageDialog = ({ click, setClick }) => {
	const [personName, setPersonName] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [age, setAge] = React.useState("");
	const [series, setSeries] = React.useState([]);
	const [subjects, setSubjects] = useState([]);
	const [classNames, setClassNames] = useState([]);
	const [totalDurationMin, setTotalDurationMin] = useState(0);
	const [domain, setDomain] = useState();
	const [science, setScience] = useState();
	const [math, setMath] = useState();
	const [english, setEnglish] = useState();
	const [tech, setTech] = useState();

	const { createPackage } = usePackages();
	const { getGroups } = useAdminHelper();
	const { getCategories } = useItemsHelper();
	const history = useHistory();
	const { user } = useSelector((state) => state.user);

	const { data: groups, status } = useQuery(["groups"], getGroups);
	const { data: categories } = useQuery(["categories"], getCategories);

	useEffect(() => {
		if (categories && categories.data) {
			let newData = [];
			for (let key in categories.data.Science) {
				for (let it in categories.data.Science[key]) {
					newData.push(it);
				}
			}
			setScience(newData);

			newData = [];
			for (let key in categories.data.Math) {
				for (let it in categories.data.Math[key]) {
					newData.push(it);
				}
			}
			setMath(newData);

			newData = [];
			for (let key in categories.data.English) {
				for (let it in categories.data.English[key]) {
					newData.push(it);
				}
			}
			setEnglish(newData);

			newData = [];
			for (let key in categories.data.Technology) {
				for (let it in categories.data.Technology[key]) {
					newData.push(it);
				}
			}
			setTech(newData);
		}
	}, [categories]);

	const handleSubmit = async () => {
		var createResp = await createPackage({
			packageName: `${classNames}-${subjects}-${series}`,
			creatorId: user?.id,
			groupId: ["liveolympiad"],
			grade: classNames,
			subject: subjects,
			difficulty: [],
			series: series,
			totalDurationMin: totalDurationMin || 0,
			tags: domain,
			//totalMarks: totalMarks || 0,
		});
		if (createResp.data) {
			var packageId = createResp.data._id;
			CustomizeToastSuccess("Package created successfully");
			history.push(`/questions/${packageId}`);
		} else {
			CustomizeToastError("Something Went Wrong !");
		}
	};

	const handleChange = (event) => {
		const value = event.target.value;
		const arrayValue = value.split(",");
		setSeries(arrayValue);
		console.log(typeof arrayValue);
	};
	const handleChang = (event) => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const handleClose = () => {
		setClick(false);
		setTotalDurationMin(0);
		setSeries([]);
		setClassNames([]);
		setSubjects([]);
		setDomain();
	};

	return (
		<PackageDialogLayout
			click={click}
			handleClose={handleClose}
			personName={personName}
			handleChang={handleChang}
			handleChange={handleChange}
			age={age}
			open={open}
			//names={names}
			categories={categories}
			groups={groups}
			subjects={subjects}
			setSubjects={setSubjects}
			classNames={classNames}
			setClassNames={setClassNames}
			handleSubmit={handleSubmit}
			totalDurationMin={totalDurationMin}
			setTotalDurationMin={setTotalDurationMin}
			domain={domain}
			setDomain={setDomain}
			math={math}
			science={science}
			english={english}
			tech={tech}
		/>
	);
};
