import * as React from "react";
import { useState, useContext, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CustomizeToastError,
  CustomizeToastSuccess,
} from "../../components/Toasts/Toasts";
import {
  useCreatePackageMutation,
  useEditPackageMutation,
  useLazyGetPackageDetailsQuery,
} from "../../redux/apis/packagesApi";
import { useGetTagsQuery } from "../../redux/apis/tags.Api";
import NewPackageLayout from "../../designs/NewPackage/NewPackageLayout";
import usePackages from "../../hooks/generic/usePackages";
import useAdminHelper from "../../hooks/generic/useAdminHelper";
import { SubjectContext } from "../../contexts/subjectContext";
import useItemsHelper from "../../hooks/generic/useItems";
import { useQuery } from "react-query";



const NewPackage = () => {
  const [assessmentName, setPackageName] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [totalDurationMin, setTotalDurationMin] = useState();
  const [totalMarks, setTotalMarks] = useState();
  const { subject, setSubject } = useContext(SubjectContext);
  // const [createPackage] = useCreatePackageMutation();
  // const [editPackage] = useEditPackageMutation();
  const {createPackage, editPackage, getPackageById} = usePackages();
  const { getGroups } = useAdminHelper();
  const { getCategories } = useItemsHelper();

  const { data: groups, status } = useQuery(["groups"], getGroups);
  const { data: categories } = useQuery(["categories"], getCategories);

  

  const {
    data: tags,
    loading: tagsLoading,
    error: tagsError,
  } = useGetTagsQuery();
  const [trigger, { data: assessmentData }] = useLazyGetPackageDetailsQuery();

  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const { pathname } = useLocation();
  const { packageId } = useParams();

  React.useEffect(() => {
    const getPackageDetails = async () => {
      const resp = await getPackageById(packageId);
      //console.log(resp);
      setPackageName(resp.data.packageName);
      setClassNames(resp.data.class);
      setSubjects(resp.data.subject);
      setTotalDurationMin(resp.data.totalDurationMin);
      setTotalMarks(resp.data.totalMarks);
    };
    if (pathname.includes("update") && packageId) {
      getPackageDetails();
    }
  }, []);

  React.useEffect(() => {
    if (assessmentData && groups && groups.data && groups.data.data && categories && categories.data) {
      setPackageName(assessmentData?.packageName);
      // setClassNames(groups.data.data || []);
      setClassNames(groups.data.data.length > 0 ? groups.data.data[0].id.toString() : '');
      setSubjects(categories.data || []);
      setDifficulty(assessmentData.difficulty || []);
      setTotalDurationMin(assessmentData.totalDurationMin || []);
      setTotalMarks(assessmentData.totalMarks || []);
    }
  }, [assessmentData,groups,categories]);

  const handleSubmit = async () => {
    if (packageId) {
      var editResp = await editPackage(packageId,{
        packageName: assessmentName,
        class: classNames,
        subject: subjects,
        difficulty: [],
        totalDurationMin: totalDurationMin || 0,
        series: (subjects===['Technology'] || subjects==='Technology') ? ["Screening"] : ["Practice"],
        totalMarks: totalMarks || 0,
      });
      if (editResp.data) {
        CustomizeToastSuccess("Package edited successfully");
        history.push("/dashboard");
      } else {
        CustomizeToastError("Something Went Wrong !");
      }
    } else {
      var createResp = await createPackage({
        packageName: assessmentName,
        creatorId: user?.id,
        groupId: user?.groupIds,
        class: classNames,
        subject: subjects,
        difficulty: [],
        series: (subjects===['Technology'] || subjects==='Technology') ? ["Screening"] : ["Practice"],
        totalDurationMin: totalDurationMin || 0,
        totalMarks: totalMarks || 0,
      });
      if (createResp.data) {
        CustomizeToastSuccess("Package created successfully");
        history.push("/dashboard");
      } else {
        CustomizeToastError("Something Went Wrong !");
      }
    }
  };
  return (
    <NewPackageLayout
        setDifficulty={setDifficulty}
        tags={tags}
        totalDurationMin={totalDurationMin}
        setTotalDurationMin={setTotalDurationMin}
        totalMarks={totalMarks}
        setTotalMarks={setTotalMarks}
        handleSubmit={handleSubmit}
        assessmentName={assessmentName}
        setPackageName={setPackageName}
        classNames={classNames}
        setClassNames={setClassNames}
        subjects={subjects}
        setSubjects={setSubjects}
        difficulty={difficulty}
        categories={categories}
        groups={groups}
    />
  );
};

export default NewPackage;
