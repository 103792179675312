  import * as React from "react";
  import { useState } from "react";
  import { useSelector } from "react-redux";
  import { useHistory, useLocation, useParams } from "react-router-dom";
  import QuestionsTable from "../../components/QuestionsTable/QuestionsTable";
  import {
    CustomizeToastError,
    CustomizeToastSuccess,
  } from "../../components/Toasts/Toasts";
  import {
      useAddQusToPackageMutation,
    useCreatePackageMutation,
    useEditPackageMutation,
    useGetPackageDetailsQuery,
  } from "../../redux/apis/packagesApi";
  import AddQuestionsToPackageLayout from "../../designs/AddQuestions/AddQuestionToPackageLayout";
  import usePackages from "../../hooks/generic/usePackages";
  
  
  const AddQuestionsToPackage = () => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [addQuestions] = useAddQusToPackageMutation();
    const [assessmentName, setPackageName] = useState("");
    const { packageId } = useParams();
    const {getPackageById} = usePackages();

    const { data: assessmentData } = getPackageById(packageId);
    const { user } = useSelector((state) => state.user);
    const history = useHistory();
    const { pathname } = useLocation();
  
  
    React.useEffect(() => {
      if (assessmentData) {
        setPackageName(assessmentData?.packageName);
      }
    }, [assessmentData]);
  
    const handleSubmit = async () => {
    if (selectedQuestions.length === 0) {
        CustomizeToastError("Please add some questions to the assessment");
    }
    var addQuesResp = await addQuestions({
        id: packageId,
        questions: selectedQuestions.map((qus) => ({
        id: qus.original._id,
        durationMin: 0,
        marks: 0,
        })),
    });
    if (addQuesResp.data) {
        CustomizeToastSuccess("Package created successfully");
        history.push("/");
    } else {
        CustomizeToastError("Something Went Wrong !");
    }
    };
    return (
      <AddQuestionsToPackageLayout 
             assessmentName={assessmentName}
             handleSubmit={handleSubmit}
             selectedQuestions={selectedQuestions}
             setSelectedQuestions={setSelectedQuestions}
      />
    );
  };
  
  export default AddQuestionsToPackage;
  