import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import QuestionsByPackageTable from "./components/QuestionsByPackageTable/QuestionsByPackageTable";
import TopBar from "./components/TopBar/TopBar";
import AddQuestions from "./pages/AddQuestions/AddQuestions";
import LoginPage from "./pages/login/LoginPage";
import NewPackage from "./pages/NewPackage/NewPackage";
import NewQuestion from "./pages/NewQuestion/NewQuestion";
import PackagesBlockList from "./pages/PackagesBlockList/PackagesBlockList";
import AllQuestionsList from "./pages/AllQuestionsList/AllQuestionsList";
import { ToastContainer } from "react-toastify";
import MathJax from "mathjax3-react";
import AddQuestionsToPackage from "./pages/AddQuestions/AddQuestionsToPackage";
import AuthorAuditScreen from "./pages/NewQuestion/AuthorAuditScreen";
import { Dashboard } from "./pages/AddQuestions/Dashboard";
import { Packages } from "./pages/PackagesBlockList/Packages";
import { PackageBlock } from "./pages/PackagesBlockList/PackageBlock";
import { SubjectContext } from "./contexts/subjectContext";
import { SubjectPanel } from "./pages/PackagesBlockList/SubjectPanel";
function App() {
  const user = useSelector((state) => state.user.user);
  const [subject, setSubject] = useState();
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user)); //user is not name of store, it the name of key inside state
  }, [user]);

  return (
    <Router>
      <ToastContainer />
      <MathJax.Provider>
        <SubjectContext.Provider value={{ subject, setSubject }}>
          <Switch>
            <Route exact path="/">
              {user ? (
                <>
                  <div>
                    {user && (user?.userRole === "AUTHOR") ? (
                      <Dashboard />
                    ) : (
                      <PackageBlock />
                    )}
                  </div>
                </>
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route path="/login">
              {!user ? <LoginPage /> : <Redirect to="/" />}
            </Route>

            {user ? (
              <>
                <Route exact path="/login">
                  <LoginPage />
                </Route>
                {/* <Route exact path="/dashboard">
                  <Dashboard />
                </Route> */}
                <Route path="/question/create">
                  <AuthorAuditScreen />
                </Route>
                <Route path="/question/update/:itemId">
                  <AuthorAuditScreen />
                </Route>
                <Route path="/packages/:packageId">
                  <QuestionsByPackageTable />
                </Route>
                <Route path="/package/create">
                  <NewPackage />
                </Route>
                <Route path="/package/update/:packageId">
                  <NewPackage />
                </Route>
                <Route path="/package/questions/:packageId">
                  <AddQuestionsToPackage />
                </Route>
                {/* <Route path="/packageblock">
                  <PackageBlock />
                </Route> */}
                <Route path="/questions/:packageId">
                  <Packages />
                </Route>
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        </SubjectContext.Provider>
      </MathJax.Provider>
    </Router>
  );
}

export default App;
