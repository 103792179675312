import React from "react";
import JoditEditor from "jodit-react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Box, Button, Typography } from "@mui/material";
import { startCase } from "lodash";

const customContentStyle = {
	width: "100%",
	maxWidth: "none",
};

function DialogBoxLayout (props) {
    return (
		<Dialog
			open={props.click}
			contentStyle={customContentStyle}
			onClose={props.handleClose}
		>
			<DialogTitle>
				<Typography
					style={{
						display: "flex",
						alignItem: "center",
						justifyContent: "center",
					}}
				>
					{startCase(props.path).split(" ")[0]}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<JoditEditor
					ref={props.editor}
					value={props.content}
					config={props.config}
					tabIndex={1} // tabIndex of textarea
					onChange={(newContent) => props.setContent(newContent)}
				/>
				<Box
					style={{
						display: "flex",
						alignItem: "center",
						justifyContent: "center",
						marginTop: 4,
					}}
				>
					<Button
						onClick={props.handleClose}
						style={{
							color: "#FFFFFF",
							width: "92px",
							height: "40px",
							backgroundColor: "#7490C7",
							boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
							borderRadius: "28px",
						}}
					>
						Save
					</Button>
					<Button
						onClick={props.handleClose}
						style={{
							marginLeft: 6,
							color: "#FFFFFF",
							width: "92px",
							height: "40px",
							backgroundColor: "#EA4C89",
							boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
							borderRadius: "28px",
						}}
					>
						Cancel
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default DialogBoxLayout;