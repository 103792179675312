import React from "react";
import Layout_layout from "../../designs/layout/Layout_layout";

const Layout = ({ children }) => {
	return (
		<Layout_layout
		    children={children}
		/>
	);
};

export default Layout;
