import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import { itemsApi } from "./apis/itemApi";
import { packagesApi } from "./apis/packagesApi";
import { tagsApi } from "./apis/tags.Api";
import { regionApi } from "./apis/region.Api";
import { milestonesApi } from "./apis/milestone.Api";
import { sentimentsApi } from "./apis/sentiment.Api";
import { usersApi } from "./apis/users.Api";
import { profileApi } from "./apis/profile.Api";
import { authApi } from "./apis/auth.Api";

const combinedReducers = combineReducers({
  user: authReducer,
  [itemsApi.reducerPath]: itemsApi.reducer,
  [packagesApi.reducerPath]: packagesApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [milestonesApi.reducerPath]: milestonesApi.reducer,
  [sentimentsApi.reducerPath]: sentimentsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
});

export const store = configureStore({
  reducer: (state, action) => {
    return combinedReducers(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      itemsApi.middleware,
      packagesApi.middleware,
      regionApi.middleware,
      milestonesApi.middleware,
      sentimentsApi.middleware,
      usersApi.middleware,
      profileApi.middleware,
      authApi.middleware,
    ]),
});
