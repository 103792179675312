import React, { useCallback, useContext, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import useAdminHelper from "../../hooks/generic/useAdminHelper";
import useItemsHelper from "../../hooks/generic/useItems";
import { SubjectContext } from "../../contexts/subjectContext";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth/authSlice";
import DropdownBoxLayout from "../../designs/NewQuestion/DropdownBoxLayout";


export default function DropdownBox({
	tags,
	setTags,
	style,
	reset,
	selectedTags,
	setSelectedTags,
	foundationTags,
	setFoundationTags,
	skill,
	setSkill,
	tagValues,
}) {
	const { subject } = useContext(SubjectContext);
	const { getGroups } = useAdminHelper();
	const { getCategories } = useItemsHelper();
	const { data: groups, status } = useQuery(["groups"], getGroups);

	const dispatch = useDispatch();
	const logoutToApp = () => {
		dispatch(logout());
	};

	if (status === 401) {
		logoutToApp();
	}

	const onChange = (e, name) => {
		setSelectedTags((prev) => ({ ...prev, [name]: e.target.value }));

		if (JSON.stringify(selectedTags) !==
			JSON.stringify(
				{
					grades: [],
					strand: [],
					domain: [],
					topic: [],
					concept: [],
				}))
			localStorage.setItem("CLASS", selectedTags.grades);
	};

	const { data: categories } = useQuery(
		["categories", subject],
		getCategories,
		{ enabled: !!subject }
	);

	const flattenJson = useCallback((json, level = 0) => {
		let flatArr = [];
		Object.keys(json).forEach((key) => {
			flatArr.push({ name: key, level: level });
			if (typeof json[key] === "object") {
				flatArr.push(...flattenJson(json[key], level + 1));
			}
		});
		return flatArr;
	}, []);

	// Segregation of current tags into different category types
	const defaultValues = useMemo(() => {
		if (categories?.data) {
			let def = {
				grades: selectedTags.grades,
				strand: selectedTags.strand,
				domain: selectedTags.domain,
				topic: selectedTags.topic,
				concept: selectedTags.concept,
			};
			const categoryType = ["strand", "domain", "topic", "concept"];
			const flatArr = flattenJson(categories?.data);
			const flatJson = {};
			flatArr.forEach((val) => {
				flatJson[val.name] = categoryType[val.level];
			});
			tags?.forEach((tag) => {
				if (
					groups?.data?.data?.map((group) => group?.groupName).includes(tag)
				) {
					def.grades.push(tag);
				}
				if (tag in flatJson) {
					def[flatJson[tag]].push(tag);
				}
			});
			return def;
		}
	}, [flattenJson, categories?.data, tags, groups?.data]);






	return (
		<DropdownBoxLayout
			setTags={setTags}
			defaultValues={defaultValues}
			foundationTags={foundationTags}
			setFoundationTags={setFoundationTags}
			skill={skill}
			setSkill={setSkill}
			categories={categories}
			reset={reset}
			tagValues={tagValues}
			style={style}
			groups={groups}
			onChange={onChange}
			selectedTags={selectedTags}
			setSelectedTags={setSelectedTags}
		/>
	)
}
