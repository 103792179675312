import React, { useContext, useEffect, useState } from "react";
import { useDeleteItemMutation } from "../../redux/apis/itemApi";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Search, Close } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import BasicTable from "../Table/BasicTable";
import MathJax from "mathjax3-react";
import {
	Box,
	Card,
	IconButton,
	List,
	ListItemText,
	Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import SelectTags from "../../pages/NewQuestion/SelectTags";
import { ListItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import useItemsHelper from "../../hooks/generic/useItems";
import LinearProgress from "@mui/material/LinearProgress";
import { SubjectContext } from "../../contexts/subjectContext";
const QuestionsTable = ({
	showActions = true,
	setSelectedItems,
	selectedItems,
	handleSubmit,
	disableRows = [],
	tags,
	subjects,
	classNames,
	domain
}) => {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const { subject } = useContext(SubjectContext);

	const { getItems } = useItemsHelper();

	
	const {
		data: questions,
		error,
		isLoading,
		refetch,
		isFetching,
	} = useQuery(["Questions", tags, subject, page, pageSize], () =>
		getItems(classNames? classNames: tags, subjects? subjects[0]: subject, page, pageSize, domain? domain[0] : "")
	);

	
	

	const [deleteQuestion] = useDeleteItemMutation();
	const history = useHistory();
	const itemColumns = [
		// {
		//   Header: "Id",
		//   accessor: "_id",
		// },
		{
			Header: "Question",
			accessor: "question",
			// maxWidth: 500,
			// minWidth: 500,
			// width: 200,
			Cell: ({ row }) => (
				// <p dangerouslySetInnerHTML={{ __html: row.original.question }} />
				<Box>
					<MathJax.Html html={row.original.question} />
				</Box>
			),
		},
		{
			// Make an expander cell
			Header: "Options", // No header
			id: "expander", // It needs an ID
			Cell: ({ row }) => (
				// Use Cell to render an expander for each row.
				// We can use the getToggleRowExpandedProps prop-getter
				// to build the expander.
				<IconButton {...row.getToggleRowExpandedProps()}>
					{row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</IconButton>
			),
		},
		{
			Header: "Answer",
			accessor: "answer",
			Cell: ({ row }) => (
				// <p dangerouslySetInnerHTML={{ __html: row.original.question }} />
				<Box>
					<MathJax.Html html={row.original.answer} />
				</Box>
			),
			// maxWidth: 400,
			// minWidth: 140,
			// width: 200,
		},
		{
			Header: "Tags",
			accessor: "tags",
			// maxWidth: 400,
			// minWidth: 400,
			// width: 200,
			Cell: ({ row }) => <>{row.original.tags?.join(", ")}</>,
		},
		{
			Header: "Created By",
			accessor: "creatorName",
			// maxWidth: 400,
			// minWidth: 140,
			// width: 200,
		},
	];
	const handleDelete = async (itemId) => {
		try {
			await deleteQuestion(itemId);
			// Refresh the data after deleting the item
			refetch();
		} catch (error) {
			console.log("Error deleting item:", error);
			// Handle the error if needed
		}
	};
	return (
		<div>
			{isLoading ? (
				<LinearProgress />
			) : error ? (
				<>Error !!</>
			) : questions?.data?.data?.items?.length ? (
				<>
					{isFetching && (
						<>
							<LinearProgress />
						</>
					)}
					<BasicTable
						data={questions?.data?.data?.items}
						columns={itemColumns}
						handleDelete={handleDelete}
						onRowsSelect={(items) => setSelectedItems(items)}
						selectedItems={selectedItems}
						handleSubmit={handleSubmit}
						disableRows={disableRows}
						showActions={showActions}
						total={questions?.data?.data?.count}
						page={page}
						pageSize={pageSize}
						setPage={setPage}
						setPageSize={setPageSize}
					/>
				</>
			) : (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
						height: "200px",
					}}
				>
					{subject ? (
						<Typography>No questions yet</Typography>
					) : (
						<Typography>Please select a subject if not selected</Typography>
					)}
				</Box>
			)}
		</div>
	);
};

export default React.memo(QuestionsTable);