import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";

export const ChangePassword = ({ click, setClick }) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [newpassword, setnewPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const handleClose = () => {
    setClick(false);
  };
  return (
    <Dialog open={click} onClose={handleClose}>
      <DialogContent
        style={{
          width: "531px",
          height: "430px",
          backgroundColor: "#E5F0FF",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Typography
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            color: "#000000",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          Change Password
        </Typography>
        <Stack direction="column">
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: "16px",
              color: "#184877",
              marginTop: 20,
            }}
          >
            Old Password
          </Typography>
          <TextField
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            inputProps={{
              sx: {
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                borderRadius: "9px",
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              },
            }}
          />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: "16px",
              color: "#184877",
              marginTop: 20,
            }}
          >
            New Password
          </Typography>
          <TextField
            placeholder="New Password"
            value={newpassword}
            onChange={(e) => setnewPassword(e.target.value)}
            fullWidth
            inputProps={{
              sx: {
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                borderRadius: "9px",
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              },
            }}
          />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: "16px",
              color: "#184877",
              marginTop: 20,
            }}
          >
            Confirm Password
          </Typography>
          <TextField
            placeholder="Confirm Password"
            value={confirmpassword}
            onChange={(e) => setconfirmPassword(e.target.value)}
            fullWidth
            inputProps={{
              sx: {
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                borderRadius: "9px",
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              },
            }}
          />
        </Stack>
        <Stack
          direction="row"
          mt="25px"
          display="flex"
          alignItem="center"
          justifyContent="center"
        >
          <Button
            style={{
              width: "112px",
              height: "47px",
              backgroundColor: "#3D76CA",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
              borderRadius: "9px",
              color: "white",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            style={{
              width: "112px",
              height: "47px",
              backgroundColor: "#EA4C89",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
              borderRadius: "9px",
              marginLeft: "23px",
              color: "white",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
