import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const packagesApi = createApi({
	reducerPath: "packagesApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.packagesURL}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Packages"],
	endpoints: (builder) => ({
		getUserCreatedPackages: builder.query({
			query: (userId) => `/user/${userId}`,
			providesTags: ["Packages"],
		}),
		getAllUserPackages: builder.query({
			query: (userId) => `/user/${userId}/all`,
			providesTags: ["Packages"],
		}),
		getAllPackagesByGrpId: builder.query({
			query: ({ groupId }) => `/group/${groupId}`,
			providesTags: ["Packages"],
		}),
		getPackageDetails: builder.query({
			query: (packageId) => `/${packageId}`,
			providesTags: ["Packages"],
		}),
		getAllPakckagesByTags: builder.query({
			query: (data) => ({
				url: `/tags`,
				method: "POST",
				body: data,
			}),
			providesTags: ["Packages"],
		}),
		createPackage: builder.mutation({
			query: (data) => ({
				url: `/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Packages"],
		}),

		editPackage: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `/${id}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["Packages"],
		}),

		addQusToPackage: builder.mutation({
			query: ({ id, questions }) => ({
				url: `/${id}`,
				method: "PUT",
				body: { action: "Add", questions },
			}),
			invalidatesTags: ["Packages"],
		}),

		deleteQusFromPackage: builder.mutation({
			query: ({ id, questions }) => ({
				url: `/${id}`,
				method: "PUT",
				body: { action: "Delete", questions },
			}),
			invalidatesTags: ["Packages"],
		}),

		deletePackage: builder.mutation({
			query: (id) => ({
				url: `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Packages"],
		}),
	}),
});

export const {
	useGetUserCreatedPackagesQuery,
	useGetAllUserPackagesQuery,
	useGetAllPackagesByGrpIdQuery,
	useGetPackageDetailsQuery,
	useLazyGetPackageDetailsQuery,
	useCreatePackageMutation,
	useEditPackageMutation,
	useDeleteQusFromPackageMutation,
	useAddQusToPackageMutation,
	useDeletePackageMutation,
	useGetAllPakckagesByTagsQuery,
} = packagesApi;
