import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as _ from "lodash";
import {
	CustomizeToastError,
	CustomizeToastSuccess,
} from "../../components/Toasts/Toasts";
import useItemsHelper from "../../hooks/generic/useItems";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SubjectContext } from "../../contexts/subjectContext";
import AuthorAuditScreenLayout from "../../designs/NewQuestion/AuthorAuditScreenLayout";
import useAdminHelper from "../../hooks/generic/useAdminHelper";

const defaultItem = {
	question: "Add Question...",
	explanation: "Add Explanation...",
	example: "Add Example...",
	justification: "Add Justification...",
	hints: ["Add Hint..."],
	options: ["Add Option..."],
	tags: [],
	foundationTags: [],
};
export default function AuthorAuditScreen() {
	const { itemId } = useParams();
	const { createItem, updateItem, getItem, addMedia, getMedia } =
		useItemsHelper();
	const { uploadFile } = useAdminHelper();

	const {
		data: item,
		error,
		isLoading,
		refetch,
		isFetching,
	} = useQuery(["Questions", itemId], () => getItem(itemId), {
		enabled: !!itemId,
	});

	const { data: media } = useQuery(["Media", itemId], () => getMedia(itemId), {
		enabled: !!itemId,
	});

	const history = useHistory();

	const { handleSubmit, control, getValues } = useForm({
		values: itemId ? item?.data : defaultItem,
	});

	const commentJson = useMemo(() => {
		let json = {};
		item?.data?.comments?.forEach((comment) => {
			json[comment["path"]] = comment["comment"];
		});
		return json;
	}, [item]);
	useEffect(() => {
		setComment(commentJson);
	}, [commentJson]);
	const [comment, setComment] = useState(commentJson ?? {});

	const [file, setFile] = useState();
	var userData = localStorage.getItem("user");
	var userObject = JSON.parse(userData);
	var username = userObject.email;

	const {
		fields: optionFields,
		append: optionAppend,
		remove: optionRemove,
	} = useFieldArray({
		control,
		name: "options",
	});
	const {
		fields: hintFields,
		append: hintAppend,
		remove: hintRemove,
	} = useFieldArray({
		control,
		name: "hints",
	});

	const { subject } = useContext(SubjectContext);
	const user = useSelector((state) => state.user.user);
	const [answer, setAnswer] = useState();
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState({
		grades: [],
		strand: [],
		domain: [],
		topic: [],
		concept: [],
	});
	const [foundationTags, setFoundationTags] = useState([]);
	const [skill, setSkill] = useState([]);

	useEffect(() => {
		if (item?.data.answer !== undefined) {
			setAnswer(item?.data.answer);
		}
		if (item?.data.foundationTags) {
			setFoundationTags(item?.data.foundationTags);
		}
		if (item?.data.cognitiveSkill != "") {
			setSkill(item?.data.cognitiveSkill);
		}
		if (item?.data.tags) {
			setSelectedTags({
				grades: item?.data.tags[0],
				strand: item?.data.tags[1],
				domain: item?.data.tags[2],
				topic: item?.data.tags[3],
				concept: item?.data.tags[4],
			});
		}
	}, [item]);
	//console.log(selectedTags.grades);

	const uploadAudioFile = async (itemId) => {
		if (file) {
			const formData = new FormData();
			formData.append("file", file, file.name);
			let res = await uploadFile(formData, "questions", itemId);
			if (res?.data?.success) {
				let fileUrl = res?.data?.data;
				console.log(fileUrl);
				const resp = await addMedia({
					mediaType: "audio/mpeg",
					mediaUrl: fileUrl,
					questionId: itemId,
				});
				if (resp?.data?.success) {
					console.log(resp?.data);
				}
			}
		}
	};

	const onSubmit = async () => {
		let values = getValues();
		let tempTags = _.uniq(_.flattenDeep(Object.values(selectedTags)));
		if (!tempTags.includes(subject)) {
			tempTags.push(subject);
		}
		//console.log(values);
		if (!values.question.trim()) {
			return CustomizeToastError("Please add a question");
		}
		if (optionFields.length < 2) {
			return CustomizeToastError("Please add at least 2 options");
		}
		if (answer === undefined || answer === "") {
			return CustomizeToastError("Please specify the answer for the question");
		}
		if (itemId && !isLoading) {
			console.log("Updating question.....", user?._id);
			let body = {
				_id: itemId,
				question: values.question,
				options: values.options,
				answer: answer,
				hints: values.hints,
				explanation: values.explanation,
				example: values.example,
				justification: values.justification,
				tags: tempTags,
				creatorId: user?.id,
				cognitiveSkill: skill ? skill[0] : "",
				creatorName: user?.fullName,
				foundationTags: foundationTags,
				comments: Object.keys(comment).map((commentKey) => {
					return {
						path: commentKey,
						comment: comment[commentKey],
					};
				}),
			};
			if (user?.userRole !== "AUTHOR") {
				delete body["creatorId"];
				delete body["creatorName"];
			}
			const resp = await updateItem(itemId, body);
			if (resp?.data) {
				await uploadAudioFile(itemId);
				CustomizeToastSuccess("Question Updated successfully");
				history.goBack();
			} else {
				return CustomizeToastError("Something went wrong !");
			}
		} else {
			console.log("Creating question.....", user?._id);
			//optionFields.forEach((value) => console.log(value));
			const resp = await createItem({
				question: values.question,
				options: values.options,
				answer: answer,
				hints: values.hints,
				tags: tempTags,
				cognitiveSkill: skill ? skill[0] : "",
				explanation: values.explanation,
				example: values.example,
				justification: values.justification,
				creatorId: user?.id,
				creatorName: user?.fullName,
				foundationTags: foundationTags,
			});

			if (resp?.data) {
				await uploadAudioFile(resp?.data?._id);
				CustomizeToastSuccess("Question Created successfully");
				history.goBack();
			} else {
				return CustomizeToastError("Something went wrong !");
			}
		}
	};

	const handleFileChange = (event) => {
		const fileObj = event?.target?.files[0];
		event.target.value = null;
		setFile(fileObj);
	};

	return (
		<AuthorAuditScreenLayout
			comment={comment}
			file={file}
			mediaUrl={media?.data?.mediaUrl}
			setFile={handleFileChange}
			setComment={setComment}
			control={control}
			setTags={setTags}
			tags={tags}
			foundationTags={foundationTags}
			setFoundationTags={setFoundationTags}
			skill={skill}
			setSkill={setSkill}
			selectedTags={selectedTags}
			setSelectedTags={setSelectedTags}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			history={history}
			hintAppend={hintAppend}
			hintFields={hintFields}
			user={user}
			hintRemove={hintRemove}
			optionAppend={optionAppend}
			optionFields={optionFields}
			answer={answer}
			setAnswer={setAnswer}
			optionRemove={optionRemove}
			username={username}
		/>
	);
}
