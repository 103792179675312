import React from "react";
import {
	Box,
	Grid,
	Typography,
	Button,
	IconButton,
	TextField,
} from "@material-ui/core";
import { InputAdornment, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Layout from "../../pages/layout/Layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterBox from "../../pages/PackagesBlockList/FilterBox";
import { PackageDialog } from "../../pages/PackagesBlockList/PackageDialog";
import { useHistory } from "react-router-dom";

function PackageBlockLayout(props) {
	const history = useHistory();
	return (
		<Layout>
			<Grid container spacing={2} mt={2} style={{ backgroundColor: "#EFEFEF" }}>
				<Grid item xl={3} lg={3} md={4} sm={5} xs={12}>
					<FilterBox
						subject={props.subject}
						setSubject={props.setSubject}
						series={props.series}
						setSeries={props.setSeries}
						grade={props.grade}
						setGrade={props.setGrade}
						handleFilter={props.handleFilter}
						handleReset={props.handleReset}
					/>
				</Grid>
				<Grid item xl={9} lg={9} md={8} sm={7} xs={12}>
					<Box style={{ width: "980px" }}>
						<Stack direction="row">
							<TextField
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<IconButton>
												<SearchIcon style={{ color: "#CAC2C2" }} />
											</IconButton>
										</InputAdornment>
									),
									style: {
										borderRadius: "12px",
										backgroundColor: "#FFFFFF",
										width: "580px",
										height: "58px",
										padding: 2,
									},
								}}
								variant="outlined"
								type="text"
								placeholder="Search"
							/>

							<Button
								style={{
									width: "300px",
									height: "58px",
									backgroundColor: "#3D76CA",
									boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
									borderRadius: "12px",
									color: "#FFFFFF",
									marginRight: "2%",
								}}
								onClick={() => props.setOpen(true)}
							>
								+ Add New Package
							</Button>
						</Stack>
					</Box>

					<Grid
						container
						mt={2}
						spacing={2}
						style={{ marginTop: 4, width: "990px" }}
					>
						{props?.data !== undefined &&
							props?.data?.map((pack) => (
								<Grid item key={pack?._id} lg={6}>
									<Box
										style={{
											width: "400px",
											height: "250px",
											backgroundColor: "#FFFFFF",
											boxShadow: "0px 3px 13px rgba(0, 0, 0, 0.25)",
											borderRadius: "12px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										padding={4}
									>
										<Stack
											direction="column"
											style={{
												display: "flex",
												justifyContent: "flex-end",
												alignItems: "flex-end",
											}}
										>
											<Stack direction="row">
												<EditIcon
													onClick={() => history.push(`/questions/${pack._id}`)}
												/>
												<DeleteIcon
													onClick={() => props.handleDelete(pack._id)}
													sx={{ marginLeft: 2, marginBottom: 2 }}
												/>
											</Stack>
											<Stack direction="column">
												<Box
													style={{
														width: "404px",
														height: "63px",
														backgroundColor: "#3D76CA",
														borderRadius: "9px",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<Typography
														style={{
															fontFamily: "Inter",
															fontWeight: 700,
															fontSize: "30px",
															color: "#77ADFC",
														}}
													>
														Subject - {pack.subject[0]}
													</Typography>
												</Box>
												<Box
													style={{
														width: "403px",
														height: "157px",
														backgroundColor: "#EFEFEF",
														borderRadius: "9px",
														marginTop: 20,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<Stack direction="column">
														<Typography
															style={{
																fontFamily: "Inter",
																fontWeight: 700,
																fontSize: "25px",
																color: "#555555",
															}}
														>
															{pack.grade[0]}
														</Typography>
														<Typography
															style={{
																fontFamily: "Inter",
																fontWeight: 700,
																fontSize: "25px",
																color: "#555555",
															}}
														>
															Questions-{pack.questions.length}
														</Typography>
														{pack.totalDurationMin && (
															<Typography
																style={{
																	fontFamily: "Inter",
																	fontWeight: 700,
																	fontSize: "25px",
																	color: "#555555",
																}}
															>
																Time-{pack.totalDurationMin}
															</Typography>
														)}
													</Stack>
												</Box>
											</Stack>
										</Stack>
									</Box>
								</Grid>
							))}
					</Grid>
				</Grid>
				<PackageDialog click={props.open} setClick={props.setOpen} />
			</Grid>
		</Layout>
	);
}

export default PackageBlockLayout;
