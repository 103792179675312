import React from "react";
import { ArrowDropDown, Cancel } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Popover, Typography } from "@mui/material";

function TagLayout(props) {
  // console.log(typeof (props.selected));
  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          style={{ color: "#FFFFFF", fontWeight: 700, fontSize: "20px" }}
        >
          {props.placeholder}
        </Typography>
        <Box
          width="95%"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Typography style={{ color: "#C0D3EF" }}>
            Select {props.placeholder}
          </Typography>
          <ArrowDropDown
            onClick={props.handleClick}
            sx={{ color: "#C0D3EF" }}
          />
        </Box>
      </Box>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {props.menu?.map((menuItem, i) => (
            <MenuItem key={i} onClick={() => props.handleSelect(menuItem)}>
              {menuItem}
            </MenuItem>
          ))}
        </Box>
      </Popover>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "50px",
          justifyContent: "end",
        }}
      >
        {props.selected &&
          typeof props.selected === "object" &&
          props.selected?.map((selected, i) => {
            return (
              <Box display="flex" key={i} gap={1} sx={{ alignItems: "center" }}>
                <Box width="50%">
                  <Typography style={{ color: "#77ADFC" }}>
                    {selected}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => props.handleSelect(selected,true)}>
                    <Cancel sx={{ color: "#77ADFC" }} />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        {props.selected &&
          typeof props.selected === "string" &&
          props.selected && (
            <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
              <Box width="50%">
                <Typography style={{ color: "#77ADFC" }}>
                  {props.selected}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={() => props.handleSelect(props.selected, true)}>
                  <Cancel sx={{ color: "#77ADFC" }} />
                </IconButton>
              </Box>
            </Box>
          )}
      </Box>
    </div>
  );
}

export default TagLayout;
