import React from "react";
import { useState } from "react";
import PackageBlockLayout from "../../designs/PackagesBlockList/PackageBlockLayout";
import usePackages from "../../hooks/generic/usePackages";
import { useEffect } from "react";

// const Package = [
//   { name: "Mathematics", questions: "30", time: "30 Min", class: "01" },
//   { name: "English", questions: "30", time: "30 Min", class: "02" },
//   { name: "Science", questions: "30", time: "30 Min", class: "03" },
//   { name: "Mathematics", questions: "30", time: "30 Min", class: "04" },
// ];

export const PackageBlock = () => {
	const [open, setOpen] = useState();
	const [grade, setGrade] = useState();
	const [subject, setSubject] = useState();
	const [series, setSeries] = useState();
	const [data, setData] = useState();

	const { getPackages, deletePackage } = usePackages();

	const handleFilter = async () => {
		const resp = await getPackages({
			grade: grade ? grade[0] : null,
			subject: subject ? subject[0] : null,
			series: series ? series[0] : null,
		});
		if (resp.data) setData(resp.data);
	};

	const handleReset = () => {
		setGrade();
		setSeries();
		setSubject();
	};

	return (
		<PackageBlockLayout
			open={open}
			setOpen={setOpen}
			// Package={Package}
			subject={subject}
			setSubject={setSubject}
			series={series}
			setSeries={setSeries}
			grade={grade}
			setGrade={setGrade}
			handleFilter={handleFilter}
			handleReset={handleReset}
			handleDelete={deletePackage}
			data={data}
		/>
	);
};
