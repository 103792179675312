import React from "react";
import AddQuestionsLayout from "../../designs/AddQuestions/AddQuestionsLayout";

function AddQuestions({ packageId }) {
  return (
    <AddQuestionsLayout/>
  );
}

export default AddQuestions;
