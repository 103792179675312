import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { clone, has } from "lodash";
import EditComponentLayout from "../../designs/NewQuestion/EditComponentLayout";

const EditComponent = ({
	value,
	setValue,
	style,
	comment,
	path,
	setComment,
}) => {
	const [commentValue, setCommentValue] = useState("");

	const [open, setOpen] = useState();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const user = useSelector((state) => state.user.user);

	const changeComment = () => {
		let commentTemp = clone(comment);
		if (!has(commentTemp, path)) {
			commentTemp[path] = [];
		}
		if (commentValue.length > 0) {
			commentTemp[path].push({ commenter: user?.fullName, body: commentValue });
		}
		setComment(commentTemp);
		setCommentValue("");
	};

	const commentOpen = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const isAuthor = useMemo(() => {
		return user?.userRole === "AUTHOR";
	}, [user]);

	return (
		<EditComponentLayout
		   style={style}
		   isAuthor={isAuthor}
		   setOpen={setOpen}
		   value={value}
		   handleClick={handleClick}
		   id={id}
		   comment={comment}
		   handleClose={handleClose}
		   anchorEl={anchorEl}
		   path={path}
		   open={open}
		   commentValue={commentValue}
		   setCommentValue={setCommentValue}
		   setValue={setValue}
		   changeComment={changeComment}
		   commentOpen={commentOpen}
		/>
	)
};

export default EditComponent;
