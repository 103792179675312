import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../environments/environment";

export const usersApi = createApi({
	reducerPath: "usersApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${environment.URL}/auth`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState()?.user?.user?.access_token;
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Users"],
	endpoints: (builder) => ({
		getAllUsers: builder.query({
			query: () => `/usersstatus`,
		}),
	}),
});

export const { useGetAllUsersQuery } = usersApi;
